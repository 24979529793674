import axios from "axios";
import { id } from "date-fns/locale";
import { language, getOrgIdLocal } from "../../src/helpers/Utils";
const base_url = process.env.REACT_APP_API_URL;

const org_instance = getOrgIdLocal()

const service_instanceID = localStorage.getItem("org_instance_id");
const defaultOptions = {
  headers: {
    "X-Authorization": "Bearer " + localStorage.getItem("token"),
    "post-id": localStorage.getItem("postId"),
    "session-id": localStorage.getItem("session_id"),
    "service-instance-id": localStorage.getItem("org_id"),
    region: "uk",
    organization: "eln",
    "Content-Type": "application/json",
    "Accept-Language": language()
  }
};



export const ORG_USER_ADDPHNNUM_VERIFY = "/Org/User/AddPhoneNbr/Verify/";
export const ORG_USER_ADDRESS_CREATE = "/Org/User/Address/Create/";
export const ORG_USER_ADDRESS_UPDATE = "/Org/User/AddressInfo/Update/";
export const ORG_USER_EMAIL_ADD = "/Org/User/Email/Add/";
export const ORG_USER_GET_USER_DETAIL = "/Org/User/GetUserDetail/";
export const GET_USER_LIST = "/Org/User/ListFetch";
export const ORG_USER_PERSONAL_INFO_UPDATE = "/Org/User/PersonalInfo/Update/";
export const ORG_USER_PHNNUM_ADD = "/Org/User/PhoneNumber/Add/";
export const ORG_USER_PRIVATE_SIGNUP = "/Org/User/PrivateSignup/";
export const ORG_USER_PROFILE_IMG_ADD = "/Org/User/ProfileImg/Add/?id=";
export const ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID = "/Org/User/ProfileImg/Add/";
export const ORG_USER_REMOVE = "/Org/User/Remove/";
export const ORG_AUTH_CHANGE_PASSWORD = "/Org/Auth/ChangePassword/";
export const GET_COUNTRY = "/county/?country-id=";
export const GET_STATE = "/state/?page_size=1000&country-id=";
export const GET_CITY = "/city/?page_size=1000&country-id=";
export const GET_COUNTRY_BY_PAGE = "/country/?page_size=1000";
export const ORG_AUTH_VERIFICATION_INPROGRESS =
  "/Org/Auth/VerificationInProgress/";
export const ORG_AUTH_RESEND_OTP = "/Org/Auth/ResendOTP/";
export const ORG_USER_USER_DETAIL_LIST = "/Org/User/UserDetail/List/";
export const ORG_AUTH_RESEND_VERIFICATION_EMAIL =
  "/Org/Auth/ResendVerificationEmail/";

// student scholarship
export const STU_EDUCATION_DETAIL = "/Student/EducationDetails/";
export const STU_FAMILY_DETAIL = "/Student/FamilyDetails/";
export const STU_PROFILE_DETAIL = "/Student/ProfileDetails/";
export const STU_PROPERTY_DETAIL = "/Student/PropertyDetails/";
export const STU_SCHOLARSHIP = "/Student/Schlorship/";
export const STU_UPDATE_PROFILE_DETAIL =
  "/Student/ProfileDetails/update_profile_details/";

export const STU_PROFILE_DETAIL_USER =
  "/Student/ProfileDetails/get_user_profile_details/?user=";
export const STU_EDUCATION_DETAIL_USER =
  "/Student/EducationDetails/get_education_details/?user=";
export const STU_FAMILY_DETAIL_USER =
  "/Student/FamilyDetails/get_family_details/?user=";
export const STU_PROPERTY_DETAIL_USER =
  "/Student/PropertyDetails/get_property_details/?user=";
export const STU_SCOLARSHIP_DETAIL_USER =
  "/Student/Schlorship/get_scholarship_details/?user=";

//Entity
export const ENTITY_PERSON_ADD = "/Entity/Person/Add/";
export const ENTITY_PERSON_EDUCATION_ADD = "/Entity/Person/Education/Add";
export const ENTITY_PERSON_FAMILY_ADD = "/Entity/Person/FamilyDetails/Add";
export const ENTITY_PERSON_OCCUPATION_ADD = "/Entity/Person/Occupation/Add";
export const ENTITY_PERSON_ASSET_ADD = "/Entity/Person/Asset/Add";
export const ENTITY_PERSON_CONTACTINFO_ADD = "/Entity/Person/ContactInfo/Add";
export const ENTITY_PERSON_LIST = "Entity/Person/List/";

export const ENTITY_PERSONAL_INFO_UPDATE =
  "/Entity/Person/PersonalInfo/Update?user=";
export const ENTITY_PERSON_EDUCATION_UPDATE = "/Entity/Person/Education/Update";
export const ENTITY_PERSON_FAMILY_UPDATE =
  "/Entity/Person/FamilyDetails/Update";
export const ENTITY_PERSON_OCCUPATION_UPDATE =
  "/Entity/Person/Occupation/Update";
export const ENTITY_PERSON_ASSET_UPDATE = "/Entity/Person/Asset/Update";
export const ENTITY_PERSON_CONTACTINFO_UPDATE =
  "/Entity/Person/ContactInfo/Update?id=";

export const ENTITY_PERSONAL_LIST = "Entity/Person/List/";
export const ENTITY_PERSON_EDUCATION_LIST = "/Entity/Person/Education/List";
export const ENTITY_PERSON_FAMILY_LIST = "/Entity/Person/FamilyDetails/List";
// export const ENTITY_PERSON_OCCUPATION_LIST = "/Entity/Person/Occupation/List"
export const ENTITY_PERSON_ASSET_LIST = "/Entity/Person/Asset/List";
export const ENTITY_PERSON_CONTACTINFO_LIST = "/Entity/Person/ContactInfo/List";

export const ENTITY_PERSONAL_INFO_LIST_BY_ID =
  "/Entity/Person/PersonalInfo/Record/Get";
export const ENTITY_PERSON_EDUCATION_LIST_BY_ID =
  "/Entity/Person/Education/Record/Get";
export const ENTITY_PERSON_FAMILY_LIST_BY_ID =
  "/Entity/Person/FamilyDetails/Record/Get";
// export const ENTITY_PERSON_OCCUPATION_LIST_BY_ID = "/Entity/Person/Occupation/Record/Get"
export const ENTITY_PERSON_ASSET_LIST_BY_ID = "/Entity/Person/Asset/Record/Get";
export const ENTITY_PERSON_CONTACTINFO_LIST_BY_ID =
  "/Entity/Person/ContactInfo/Record/Get";

export const ORG_ROLES_LIST = "/Org/Roles/List/";
export const ORG_POST_LIST = "/Org/Posts/List/";
export const ORG_ROLES_SERVICE_INSTANCE_LIST =
  "/Org/Roles/Service/Instance/List/";

export const ORG_SERVICES_SERVICEINSTANCEBYIDENTIFIER_GET = "/Org/Services/ServiceInstancesByIdentifier/Get/"

export function getOrgId(id, hostName) {
  return axios.get(base_url + ORG_SERVICES_SERVICEINSTANCEBYIDENTIFIER_GET + id + "/?domain=" + hostName)
  // return axios.get(base_url + ORG_SERVICES_SERVICEINSTANCEBYIDENTIFIER_GET + id + "/?domain=localhost:3000")
}

export function createPerson() {
  return axios.post(ENTITY_PERSON_ADD);
}
export function createEduDetail(value, userId) {
  return axios.post(ENTITY_PERSON_EDUCATION_ADD, {
    user: userId,
    education: [value]
  });
}
export function createFamilyDetail(values, userId) {
  return axios.post(ENTITY_PERSON_FAMILY_ADD, {
    user: userId,
    family_details: [values]
  });
}

export function createOccupationDetail(values, userId) {
  return axios.post(ENTITY_PERSON_OCCUPATION_ADD, {
    user: userId,
    occupation: [values]
  });
}
export function createAssetDetail(values, userId) {
  return axios.post(ENTITY_PERSON_ASSET_ADD, { user: userId, asset: [values] });
}
export function createContactDetail() {
  return axios.post(ENTITY_PERSON_CONTACTINFO_ADD);
}

export function updatePerson(userId, values) {
  return axios.patch(ENTITY_PERSONAL_INFO_UPDATE + userId, values);
}
export function updateEduDetail(value, userId) {
  return axios.patch(ENTITY_PERSON_EDUCATION_UPDATE, {
    user: userId,
    education: [value]
  });
}
// export function updateFamilyDetail() {
//   return axios.patch(ENTITY_PERSON_FAMILY_UPDATE + userId );
// }

export function updateFamilyDetail(values, userId) {
  return axios.patch(ENTITY_PERSON_FAMILY_UPDATE, {
    user: userId,
    family_details: [values]
  });
}

export function updateOccupationDetail(values, userId) {
  return axios.patch(ENTITY_PERSON_OCCUPATION_UPDATE, {
    user: userId,
    occupation: [values]
  });
}
export function updateAssetDetail(values, userId) {
  return axios.patch(ENTITY_PERSON_ASSET_UPDATE, {
    user: userId,
    asset: [values]
  });
}
export function updateContactDetail() {
  return axios.patch(ENTITY_PERSON_CONTACTINFO_UPDATE + userId);
}

export function getListPerson() {
  return axios.get(
    ENTITY_PERSONAL_LIST + '?page_size=1000/' + "?service_instance=" + service_instanceID
  );
}
export function getEduList() {
  return axios.get(ENTITY_PERSON_EDUCATION_LIST);
}
export function getFamilyList(id) {
  return axios.get(ENTITY_PERSON_FAMILY_LIST + "?user=" + id);
}
// export function getOccupationList() {
//   return axios.get(ENTITY_PERSON_OCCUPATION_LIST  );
// }
export function getAssetList(id) {
  return axios.get(ENTITY_PERSON_ASSET_LIST + "?user=" + id);
}
export function getContactList() {
  return axios.get(ENTITY_PERSON_CONTACTINFO_LIST);
}

export function getListPersonByRadioId(id) {
  return axios.get(ENTITY_PERSONAL_INFO_LIST_BY_ID + id);
}
export function getEduListByRadioId(id) {
  return axios.get(ENTITY_PERSON_EDUCATION_LIST_BY_ID + id);
}
export function getFamilyListByRadioId(id) {
  return axios.get(ENTITY_PERSON_FAMILY_LIST_BY_ID + "?id=" + id);
}
// export function getOccupationListByRadioId(id) {
//   return axios.get(ENTITY_PERSON_OCCUPATION_LIST_BY_ID + id );
// }
export function getAssetListByRadioId(id) {
  return axios.get(ENTITY_PERSON_ASSET_LIST_BY_ID + id);
}
export function getContactListByRadioId(id) {
  return axios.get(ENTITY_PERSON_CONTACTINFO_LIST_BY_ID + id);
}

export function getStuProfileDetailListByUserId(id) {
  return axios.get(STU_PROFILE_DETAIL_USER + id);
}
export function getStuEducationDetailListByUserId(id) {
  return axios.get(STU_EDUCATION_DETAIL_USER + id);
}
export function getStuFamilyDetailListByUserId(id) {
  return axios.get(STU_FAMILY_DETAIL_USER + id);
}
export function getStuPropertyDetailListByUserId(id) {
  return axios.get(STU_PROPERTY_DETAIL_USER + id);
}
export function getStuScholarshipDetailListByUserId(id) {
  return axios.get(STU_SCOLARSHIP_DETAIL_USER + id);
}

// student education
export function createStuEducationDetails(values) {
  // return axios.post( STU_EDUCATION_DETAIL,values)
  // return axios.post( STU_EDUCATION_DETAIL,{
  //   institution_type:instituteType,
  //   qualification:values.qualification,
  //   department:values. department,
  //   year:values. year,
  //   institution_name:values. institution_name,
  //   medium:values. medium,
  //   status:values. status,
  //   percentage:values. percentage,
  //   address_line1:values. address_line1,
  //   address_line2:values. address_line2,
  //   city:values. city,
  //   taluk:values. taluk,
  //   district:values. district,
  //   country_code:fetchCountryId,
  //   phn_nbr:values. phn_nbr[0],
  //   user: id,
  // });
}
export function getStuEducationDetailList() {
  return axios.get(STU_EDUCATION_DETAIL);
}
export function getStuEducationDetailListById(id) {
  return axios.get(STU_EDUCATION_DETAIL + id + "/");
}

export function UpdateStuEducationDetail(values) {
  return axios.post(STU_EDUCATION_DETAIL, values);
  // return axios.patch(STU_EDUCATION_DETAIL + id + "/", {
  //   institution_type:values.institution_type,
  //   qualification:values.qualification,
  //   department:values. department,
  //   year:values. year,
  //   institution_name:values. institution_name,
  //   medium:values. medium,
  //   status:values. status,
  //   percentage:values. percentage,
  //   address_line1:values. address_line1,
  //   address_line2:values. address_line2,
  //   city:values. city,
  //   taluk:values. taluk,
  //   district:values. district,
  //   country_code:fetchCountryId,
  //   phn_nbr:values. phn_nbr[0],
  //   // user: userid,
  // });
}
export function removeStuEducationDetail(id) {
  return axios.delete(STU_EDUCATION_DETAIL + id + "/");
}

// student family
export function createStuFamilyDetails(values) {
  return axios.post(STU_FAMILY_DETAIL, values);
}
export function getStuFamilyDetailList() {
  return axios.get(STU_FAMILY_DETAIL);
}
export function getStuFamilyDetailListById(id) {
  return axios.get(STU_FAMILY_DETAIL + id + "/");
}

export function UpdateStuFamilyDetail(id, values) {
  return axios.patch(STU_FAMILY_DETAIL + id + "/", values);
}
export function removeStuFamilyDetail(id) {
  return axios.delete(STU_FAMILY_DETAIL + id + "/");
}

// student profile

export function createStuProfileDetails(values) {
  return axios.post(STU_PROFILE_DETAIL, values);
}
export function getStuProfileDetailList() {
  return axios.get(STU_PROFILE_DETAIL);
}
export function getStuProfileDetailListById(id) {
  return axios.get(STU_PROFILE_DETAIL + id + "/");
}

export function UpdateStuProfileDetail(id, values) {
  return axios.patch(STU_PROFILE_DETAIL + id + "/", values);
}
export function removeStuProfileDetail(id) {
  return axios.delete(STU_PROFILE_DETAIL + id + "/");
}

// student property

export function createStuPropertyDetails(values) {
  return axios.post(STU_PROPERTY_DETAIL, values);
}
export function getStuPropertyDetailList() {
  return axios.get(STU_PROPERTY_DETAIL);
}
export function getStuPropertyDetailListById(id) {
  return axios.get(STU_PROPERTY_DETAIL + id + "/");
}

export function UpdateStuPropertyDetail(id, values) {
  return axios.patch(STU_PROPERTY_DETAIL + id + "/", values);
}
export function removeStuPropertyDetail(id) {
  return axios.delete(STU_PROPERTY_DETAIL + id + "/");
}

// student scholarship
export function createStuScholarshipDetails(values) {
  return axios.post(STU_SCHOLARSHIP, values);
}
export function getStuScholarshipDetailList() {
  return axios.get(STU_SCHOLARSHIP);
}
export function getStuScholarshipDetailListById(id) {
  return axios.get(STU_SCHOLARSHIP + id + "/");
}

export function UpdateStuScholarshipDetail(id, values) {
  return axios.patch(STU_SCHOLARSHIP + id + "/", values);
}
export function removeStuScholarshipDetail(id) {
  return axios.delete(STU_SCHOLARSHIP + id + "/");
}

export function resendVerificationEmail(data) {
  return axios.post(ORG_AUTH_RESEND_VERIFICATION_EMAIL, data);
}

export function getUserDetailList(node_id, currentPage) {
  return axios.get(ORG_USER_USER_DETAIL_LIST + "?node_id=" + node_id);
}

export function createProfileImage(id) {
  return axios.post(ORG_USER_PROFILE_IMG_ADD + id);
}

export function userPhnNumVerify(phn_nbr, OTP) {
  return axios.post(ORG_USER_ADDPHNNUM_VERIFY, { phn_nbr: phn_nbr, code: OTP });
}

export function resendOtp(phn_nbr, firm) {
  return axios.post(ORG_AUTH_RESEND_OTP, {
    phn_nbr: phn_nbr,
    firm: parseInt(firm)
  });
}

export function verificationInprogress(code) {
  return axios.get(ORG_AUTH_VERIFICATION_INPROGRESS + code);
}

export function createProfileImg(image) {
  return axios.post(ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID, {
    image: image
  });
}

export function createEmail(email) {
  return axios.post(ORG_USER_EMAIL_ADD, {
    email: email
  });
}
export function createPhnNbr(phn_nbr, country_id) {
  return axios.post(ORG_USER_PHNNUM_ADD, {
    phn_nbr: phn_nbr[0],
    country_id: country_id
  });
}
export function getCountryPg() {
  return axios.get(GET_COUNTRY_BY_PAGE);
}
export function getCountyList(id) {
  return axios.get(GET_COUNTRY + id);
}

export function getStateList(id) {
  return axios.get(GET_STATE + id);
}
export function getCityList(countryId, stateId) {
  return axios.get(
    GET_CITY + countryId + "&state-id=" + stateId
  );
}

export function updateUserAddInfo(id, value) {
  return axios.patch(ORG_USER_ADDRESS_UPDATE + id + "/", value);
}
export function createUserAddInfo(value) {
  return axios.post(ORG_USER_ADDRESS_CREATE, value);
}
export function updatePassword(value) {
  return axios.patch(ORG_AUTH_CHANGE_PASSWORD, value);
}

export function updateUserPersonalInfo(id, value) {
  return axios.patch(ORG_USER_PERSONAL_INFO_UPDATE + "?id=" + id, value);
}

// get users list with pagination

export function getUserList(page) {
  return axios.get(GET_USER_LIST + page);
}

export function getAllUserList(id) {
  return axios.get(ORG_USER_GET_USER_DETAIL + id + "/");
}

// get single user
export function getSingleUser(id) {
  return axios.get(ORG_USER_PRIVATE_SIGNUP + id + "/");
}



// update a user
export function patchUser(id, values) {
  return axios.patch(ORG_USER_PRIVATE_SIGNUP + id + "/", values);
}



export const HTTP_CODE = {
  ACCEPTED: 202,
  BADREQUEST: 400,
  CONFLICT: 409,
  CREATED: 201,
  OK: 200
};

export const ENTITY_EVENT_ADD = "/Entity/Event/Add";

export const ENTITY_EVENT_GET = "/Entity/Event/Get";

export const ENTITY_EVENT_LIST = "/Entity/Event/List";

export const ENITITY_EVENT_EVENTINFO_UPDATE = "/Entity/Event/EventInfo/Update";
export const API_ENTITY_EVENT_REMOVE = "Entity/Event/Remove/"

export function ApiEntityEventRemove(id) {
  return axios.delete(API_ENTITY_EVENT_REMOVE + id);
}
export const API_ENTITY_EVENT_ADD = "Entity/Event/Add"
export function ApiEntityEventAdd(data) {
  return axios.post(API_ENTITY_EVENT_ADD + data);
}

export function getEntityEventGet() {
  return axios.get(ENTITY_EVENT_GET + "?id=1");
}
export function getEntityList() {
  return axios.get(ENTITY_EVENT_LIST);
}
export function patchEntityEventInfoUpdate(values) {
  return axios.patch(ENITITY_EVENT_EVENTINFO_UPDATE, values);
}

export const ENTITY_PERSON_PERSONALINFO_UPDATE =
  "/Entity/Person/PersonalInfo/Update";

export function updatePersonalInfo(data) {
  return axios.patch(ENTITY_PERSON_PERSONALINFO_UPDATE, data);
}

//User Profile Delete
export const API_ENTITY_PERSON_PROFILEIMGE_REMOVE = "Entity/Person/ProfileImage/Delete"

export function ApiEntityPersonProfileImageRemove(data) {
  return axios.delete(API_ENTITY_PERSON_PROFILEIMGE_REMOVE, { data });
}


export const ENTITY_PERSON_GET = "/Entity/Person/Get/";
export function getPersonDetails(id) {
  return axios.get(ENTITY_PERSON_GET + "?user=" + id);
}

// Education Course List
export const ENTITY_PERSON_EDUCATION_LISTCOURSETYPE =
  "/Entity/Education/ListCourseTypes";
export function getEducationListCourseType() {
  return axios.get(ENTITY_PERSON_EDUCATION_LISTCOURSETYPE + "?service_instance=" + service_instanceID);
}

// Education ListMediums List
export const ENTITY_EDUCATION_LISTMEDIUMS = "Entity/Education/ListMediums";
export function getEducationListMediums() {
  return axios.get(ENTITY_EDUCATION_LISTMEDIUMS);
}

// Education ListMediums List
export const ENTITY_EDUCATION_PERSON_LISTSPECIALIZATIONS = "Entity/Person/ListSpecializations"
export function getEducationListSpecializations(id) {
  return axios.get(ENTITY_EDUCATION_PERSON_LISTSPECIALIZATIONS + "?service_instance=" + id);
}

//Education class list 
export const ENTITY_EDUCATION_CLASS_LIST = "Entity/Education/ListClassTypes"
export function getEducationClassList(id, tagId) {
  return axios.get(ENTITY_EDUCATION_CLASS_LIST + "?service_instance=" + id + "&tagset=" + tagId);
}

export const ENTITY_PERSON_EDUCATION_REMOVE =
  "Entity/Person/Education/Record/Remove";
export function deleteEducationDetails(data) {
  return axios.delete(ENTITY_PERSON_EDUCATION_REMOVE, data);
}

// Get Api Relation List
export const ENTITY_PERSON_LISTRELATIONS = "Entity/Person/ListRelations";
export function getPersonListRelations() {
  return axios.get(ENTITY_PERSON_LISTRELATIONS);
}

// Delete Api FamilyMember
export const ENTITY_PERSON_FAMILYMEMBER_RECORD_REMOVE = "Entity/Person/FamilyDetails/Record/Remove"
export function deletePersonFamilyMemberRecordRemove(data) {
  return axios.delete(ENTITY_PERSON_FAMILYMEMBER_RECORD_REMOVE, data);
}

// Delete Api FamilyMember
export const ENTITY_PERSON_PROPERTY_RECORD_REMOVE = "Entity/Person/Asset/Record/Remove"
export function deletePersonPropertyRecordRemove(data) {
  return axios.delete(ENTITY_PERSON_PROPERTY_RECORD_REMOVE, data);
}

// Delete Api Occupation
export const ENTITY_PERSON_OCCUPATION_RECORD_REMOVE =
  "/Entity/Person/Occupation/Record/Remove";
export function deletePersonOccupationRecordRemove(data) {
  return axios.delete(ENTITY_PERSON_OCCUPATION_RECORD_REMOVE, data);
}

// Get Api OccupationList
export const ENTITY_PERSON_OCCUPATION_LIST = "/Entity/Person/Occupation/List";
export function getOccupationList(id) {
  return axios.get(ENTITY_PERSON_OCCUPATION_LIST + "?user=" + id);
}

// Get Api GenderList
export const ENTITY_PERSON_LISTGENDER = "Entity/Person/ListGender";
export function getGenderList() {
  return axios.get(ENTITY_PERSON_LISTGENDER);
}

// Get Api ListMaritalStates
export const ENTITY_PERSON_LISTMARITALSTATES =
  "Entity/Person/ListMaritalStates";
export function getMaritalList() {
  return axios.get(ENTITY_PERSON_LISTMARITALSTATES);
}

// Get Api ListOccupation
export const ENTITY_OCCUPATION_LISTTYPE = "/Entity/Occupation/ListTypes";
export function getOccupationlList() {
  return axios.get(ENTITY_OCCUPATION_LISTTYPE);
}

// Get Api Occupation Record
export const ENTITY_PERSON_OCCUPATION_RECORD_GET =
  "/Entity/Person/Occupation/Record/Get";
export function getOccupationRecord(id) {
  return axios.get(ENTITY_PERSON_OCCUPATION_RECORD_GET + "?id=" + id);
}

// scholarship Api
export const ENTITY_SCHOLARSHIP_PLAN_GET = "/Entity/Scholarship/Plan/Get";
export const ENTITY_SCHOLARSHIP_PLAN_LIST = "/Entity/Scholarship/Plan/List";
export const ENTITY_SCHOLARSHIP_PLAN_ADD = "/Entity/Scholarship/Plan/Add";
export const ENTITY_SCHOLARSHIP_PLAN_UPDATE = "/Entity/Scholarship/Plan/Update";
export const ENTITY_SCHOLARSHIP_PLAN_REMOVE = "/Entity/Scholarship/Plan/Remove";

export function getEnitySchilarshipPlanGet(id) {
  return axios.get(ENTITY_SCHOLARSHIP_PLAN_GET + "plan=" + id);
}

export function postEnitySchilarshipPlanAdd(data) {
  return axios.post(ENTITY_SCHOLARSHIP_PLAN_ADD, data);
}
export function patchEnitySchilarshipPlanUpdate(data) {
  return axios.patch(ENTITY_SCHOLARSHIP_PLAN_UPDATE, data);
}
export function deleteEnitySchilarshipPlan(id) {
  return axios.delete(ENTITY_SCHOLARSHIP_PLAN_REMOVE + "?id=" + id);
}

export const ENTITY_EVENT_MEDIA_ADD = "/Entity/Event/Media/Add";
export const ENTITY_EVENT_MEDIA_GET = "/Entity/Event/Media/Get";
export const ENTITY_EVENT_MEDIA_LIST = "/Entity/Event/Media/List";
export const ENTITY_EVENT_MEDIA_REMOVE = "Entity/Event/Media/Remove";

export function deleteEnityEventMediaRemove(data) {
  return axios.delete(ENTITY_EVENT_MEDIA_REMOVE, data);
}
// Get Profession List
export const ENTITY_TAGSET_LIST = "/Entity/Tagset/List";
export function getEntityTagsetList(id) {
  return axios.get(
    ENTITY_TAGSET_LIST + "?service_instance=" + id + "&entity_type=person"
  );
}

// Get Education List
export const ENTITY_EDUCATION_LISTEDUCATIONTYPES = "Entity/Education/ListEducationTypes";
export function getEntityEducationListType(id) {
  return axios.get(ENTITY_EDUCATION_LISTEDUCATIONTYPES + "?service_instance=" + id);
}

// Get Country List
export function getCountryList() {
  return axios.get(GET_COUNTRY_BY_PAGE);
}

export const ENTITY_FORM_QUESTION_ADD = "Entity/Form/Question/Add";
export function postEnityFormQuestionAdd(data) {
  return axios.post(ENTITY_FORM_QUESTION_ADD, data);
}
export const ENTITY_FORM_INPUT_TYPES_LIST = "/Entity/InputTypes?page_size=1000"
export const ENTITY_FORM_QUESTION_TYPES_LIST = "Entity/Form/QuestionTypes/List";

export function getEnityFormInputTypes() {
  return axios.get(ENTITY_FORM_INPUT_TYPES_LIST);
}

export const ENTITY_FORM_QUESTION_LIST = "Entity/Form/Question/List";

export const ENTITY_FORM_QUESTION_UPDATE = "Entity/Form/Question/Update";
export function patchEnityFormQuestionUpdate(data) {
  return axios.patch(ENTITY_FORM_QUESTION_UPDATE, data);
}

export const ENTITY_FORM_QUESTION_GET = "Entity/Form/Question/Get";
export function getEnityFormQuestionGet(id) {
  return axios.get(ENTITY_FORM_QUESTION_GET + "?question_id=" + id);
}

export const ENTITY_FORM_QUESTION_REMOVE = "Entity/Form/Question/Remove";
export function deleteEnityFormQuestionRemove(data) {
  return axios.delete(ENTITY_FORM_QUESTION_REMOVE, data);
}

export const API_ENTITY_FORM_QUESTION_UPDATE = "/Entity/Form/Question/Update";
export function EnityFormQuestionUpdate(data) {
  return axios.patch(API_ENTITY_FORM_QUESTION_UPDATE, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_GET = "Entity/Form/AnswerChoice/Get";

export function getEntityFormAnswerChoiceGet(id) {
  return axios.get(ENTITY_FORM_ANSWER_CHOICE_GET + "?id=" + id);
}

export const ENTITY_FORM_ANSWER_CHOICE_ADD = "Entity/Form/AnswerChoice/Add";
export function postQuestionChoiceAdd(data) {
  return axios.post(ENTITY_FORM_ANSWER_CHOICE_ADD, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_UPDATE =
  "Entity/Form/AnswerChoice/Update";
export function patchEnityFormAnswerChoiceUpdate(data) {
  return axios.patch(ENTITY_FORM_ANSWER_CHOICE_UPDATE, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_REMOVE =
  "Entity/Form/AnswerChoice/Remove";

export function deleteEnityFormAnswerChoiceRemove(id) {
  return axios.delete(ENTITY_FORM_ANSWER_CHOICE_REMOVE, id);
}

export const STORE_ORDER_STATISTICES = "Entity/Order/Statics";

//
export const SCHOLARSHIP_APPLICATION_ADD =
  "/Entity/Scholarship/Application/Add";
export function postScholarshipApplicationAdd(data) {
  return axios.post(SCHOLARSHIP_APPLICATION_ADD, data);
}

export const SCHOLARSHIP_APPLICATION_STATUS_UPDATE =
  "/Entity/Scholarship/Application/ApplicationStatusUpdate";
export function ScholarshipApplicationStatusUpdate(data) {
  return axios.patch(SCHOLARSHIP_APPLICATION_STATUS_UPDATE, data);
}

export const SCHOLARSHIP_APPLICATION_REMOVE =
  "/Entity/Scholarship/Application/Remove";
export function deleteScholarshipApplicationRemove(id) {
  return axios.delete(SCHOLARSHIP_APPLICATION_REMOVE, id);
}

export const API_ENTITY_SCHOLARSHIP_LIST =
  "/Entity/Scholarship/Application/List";
export const API_ENTITY_DONATION_LIST = "Entity/Donation/ServiceInstance/List";

export const API_ENTITY_PERSON_GET = "Entity/Person/Get/";
export const API_ENTITY_SCHOLARSHIP_APPLICATION_GET =
  "/Entity/Scholarship/Application/Get";

export const API_ADD_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Add";
export function postFormAnswerAdd(data) {
  return axios.post(API_ADD_ENTITY_FORM_ANSWER, data);
}

export const API_DELETE_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Remove";
export function deleteFormAnswerAdd(id) {
  return axios.delete(API_DELETE_ENTITY_FORM_ANSWER + "?id=" + id);
}

export const API_UPDATE_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Update";
export function patchFormAnswerUpdate(data) {
  return axios.patch(API_UPDATE_ENTITY_FORM_ANSWER, data);
}

// Profession Sectors List
export const ENTITY_OCCUPATION_PROFESSIONSECTORS =
  "Entity/Occupation/ProfessionSectors";

export function getProfessionSectors() {
  return axios.get(ENTITY_OCCUPATION_PROFESSIONSECTORS + "?service_instance=" + service_instanceID);
}

// Profession List
export const ENTITY_OCCUPATION_PROFESSION = "Entity/Occupation/Profession";

export function getOccupationProfession() {
  return axios.get(ENTITY_OCCUPATION_PROFESSION + "?service_instance=" + service_instanceID);
}

//aprroved status
export const APPLICATION_APPROVE_STATUS =
  "/Entity/Scholarship/Application/ApproveStatusUpdate";

export function UpdateApplicationStatus(data) {
  return axios.patch(APPLICATION_APPROVE_STATUS, data);
}
// Education Status List
export const ENTITY_EDUCATION_STATUS_LIST = "Entity/Education/Status/List";

export function getEducationStatusList() {
  return axios.get(ENTITY_EDUCATION_STATUS_LIST);
}

// Family Occupation List
export const ENTITY_FAMILYDETAILS_OCCUPATION =
  "Entity/FamilyDetails/Occupation";

export function getFamilyDetailsOccupation(id) {
  return axios.get(ENTITY_FAMILYDETAILS_OCCUPATION + "?service_instance=" + id);
}

// Property Type List
export const ENTITY_PERSON_ASSET_LISTTYPES = "Entity/Person/Asset/ListTypes";

export function getPersonAssetListTypes(id) {
  return axios.get(ENTITY_PERSON_ASSET_LISTTYPES + "?service_instance=" + id);
}

// Property Unit List
export const ENTITY_PERSON_ASSET_UNITTYPES = "Entity/Person/Asset/UnitTypes";

export function getPersonAssetUnitTypes() {
  return axios.get(ENTITY_PERSON_ASSET_UNITTYPES);
}

// Get Auth by Token
export const ORG_AUTH_GETBYTOKEN = "Org/Auth/GetByToken/";

export function getAuthByToken() {
  return axios.create(defaultOptions).get(ORG_AUTH_GETBYTOKEN);
}

// Get Ambition List
export const ENTITY_PERSON_AMBITION_LIST = "Entity/Person/Ambition/List";

export function getAmbitionList() {
  return axios.get(ENTITY_PERSON_AMBITION_LIST);
}

// Get Service Instance List
export const ORG_SERVICES_SERVICEINSTANCE_LIST =
  "Org/Services/ServiceInstances/List/";

export function gerServiceInstanceList() {
  return axios.get(ORG_SERVICES_SERVICEINSTANCE_LIST);
}

// User Status UpdateApi
export const ORG_USER_UPDATE_REGISTRATION_STATUS =
  "Org/User/UpdateRegistrationStatus/";

export function updateUserRegistrationStatus(data, id) {
  return axios.patch(ORG_USER_UPDATE_REGISTRATION_STATUS + "?id=" + id, data);
}

// Role DeleteApi
export const ORG_ROLES_REMOVE = "/Org/Roles/Remove/";

export const ORG_ROLES_SERVICE_REMOVE = "Org/Roles/Service/Remove/";

// Role Group List
export const ORG_ROLES_GROUP_LIST = "Org/Roles/Service/List/";


// Role GetApi
export const ORG_ROLES_GETROLEDETAILS = "/Org/Roles/GetRoleDetails/";

// Role UpdateApi
export const ORG_ROLES_UPDATE = "/Org/Roles/Update/";

// Role CreateApi
export const ORG_ROLES_CREATE = "/Org/Roles/Add/";








//
export const ENTITY_SCHOLARSHIP_APPLICATION_STATISTICS_GET =
  "Entity/Scholarship/Application/Statistics/Get";
export function getEntityScholarshipApplicationStatisticsGet() {
  return axios.get(ENTITY_SCHOLARSHIP_APPLICATION_STATISTICS_GET + "?service_instance=" + service_instanceID);
}

//Dhasboard
export const ENTITY_PERSON_STATISTICS_GET = "/Entity/Person/Statistics/Get?";
export function getEntityPersonStatisticsGet() {
  return axios.get(ENTITY_PERSON_STATISTICS_GET + "service_instance=" + service_instanceID);
}

// Vountering Type List
export const ENTITY_PERSON_LISTVOLUNTEERING_TYPE = "/Entity/Person/ListVolunteeringType";
export function getVolunteringType(id) {
  return axios.get(ENTITY_PERSON_LISTVOLUNTEERING_TYPE + "?service_instance=" + service_instanceID);
}

// Dropdown Types
export const ENTITY_ATTRIBUTE_CHOICES_TYPES = "/Entity/Attribute/Choices/Types";
export function getAttributeChoicesType() {
  return axios.get(ENTITY_ATTRIBUTE_CHOICES_TYPES);
}

// Dropdown get 
export const ENTITY_ATTRIBUTE_CHOICES_GET = "/Entity/Attribute/Choices/Get";
export function getAttributeChoicesGet(id) {
  return axios.get(ENTITY_ATTRIBUTE_CHOICES_GET + "?id=" + id);
}


// Dropdown List
export const ENTITY_ATTRIBUTE_CHOICES_LIST = "Entity/Attribute/Choices/List";
export function getAttributeChoicesList(id) {
  return axios.get(
    ENTITY_ATTRIBUTE_CHOICES_LIST +
    "?service_instance=" +
    localStorage.getItem("org_instance_id") +
    "&category_type=" +
    id
  );
}

// Dropdown Choices Add
export const ENTITY_ATTRIBUTE_CHOICES_ADD = "/Entity/Attribute/Choices/Add";
export function getAttributeChoicesAdd(data) {
  return axios.post(ENTITY_ATTRIBUTE_CHOICES_ADD, data);
}

// Dropdown Choices Update
export const ENTITY_ATTRIBUTE_CHOICES_UPDATE = "/Entity/Attribute/Choices/Update/";
export function getAttributeChoicesUpdate(id, data) {
  return axios.patch(ENTITY_ATTRIBUTE_CHOICES_UPDATE + id + "/", data);
}


// Dropdown Choices Delete
export const ENTITY_ATTRIBUTE_CHOICES_REMOVE =
  "/Entity/Attribute/Choices/Remove";
export function deleteChoices(id) {
  return axios.delete(ENTITY_ATTRIBUTE_CHOICES_REMOVE + "?id=" + id);
}

//Report Api
export const API_ENTITY_PERSON_FILTERPARAMS_TYPE =
  "/Entity/Person/FilterParams/List?";
export function ApiReportsFilterParams() {
  return axios.get(API_ENTITY_PERSON_FILTERPARAMS_TYPE);
}

//Refresh Token
export const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/";
export function refreshAccessToken(data) {
  return axios.post(ORG_AUTH_REFRESH, data);
}

// Tagset APi Section start
// Tagset List
export const API_ENTITY_TAGSET_WIDGETRECORD_LIST = "Entity/Tagset/WidgetRecord/List";
export function apiEntityTagsetWidgetrecordList(id) {
  return axios.get(API_ENTITY_TAGSET_WIDGETRECORD_LIST + "?service_instance=" + id);
}

//Tagset config add
export const API_ENTITY_TAGSET_CONFIG_ADD = "Entity/Config/Tagset/Add";
export function apiEntityTagsetConfigAdd(data) {
  return axios.post(API_ENTITY_TAGSET_CONFIG_ADD + data);
}

//Tagset Update
export const API_ENTITY_WIDGET_TAGSET_UPDATE = "/Entity/Tagset/WidgetRecord/Update/";
export function apiEntityWidgetTagsetUpdate(id, value) {
  return axios.patch(API_ENTITY_WIDGET_TAGSET_UPDATE + id, value);
}

//Tagset Delete
export const API_ENTITY_TAGSET_DELETE = "/Entity/Tagset/WidgetRecord/Remove?id=";
export function apiEntityTagsetRemove(id) {
  return axios.delete(API_ENTITY_TAGSET_DELETE + id);
}

//Tags API
//Tags list
export const API_WIDGETRECORD_TAGS_LIST = "/Entity/Tag/WidgetRecord/List?page_size=1000&tagset=";
export function apiWidgetRecordTagsList(id) {
  return axios.get(API_WIDGETRECORD_TAGS_LIST + id);
}
//tgs get
export const API_WIDGETRECORD_TAGS_GET = "/Entity/Tag/WidgetRecord/Get?id=";
export function apiWidgetRecordTagsGet(id) {
  return axios.get(API_WIDGETRECORD_TAGS_GET + id);
}
//Tags Update
export const API_ENTITY_TAGS_UPDATE = "/Entity/Tag/WidgetRecord/Update/";
export function apiEntityTagsUpdate(id, value) {
  return axios.patch(API_ENTITY_TAGS_UPDATE + id, value);
}
//Tags Add
export const API_WIDGETRECORD_TAGS_ADD = "/Entity/Tag/WidgetRecord/Add";
export function apiWidgetRecordTagsAdd() {
  return axios.get(API_WIDGETRECORD_TAGS_ADD);
}

//Tagset Delete
export const API_ENTITY_TAGS_DELETE = "/Entity/Tag/WidgetRecord/Remove";
export function apiEntityTagsRemove(id) {
  return axios.delete(API_ENTITY_TAGS_DELETE + id);
}

//Tagchoise API
//Tagchoise List
export const API_WIDGETRECORD_TAGCHOICE_LIST = "/Entity/TagChoice/WidgetRecord/List?tag_id=";
export function apiWidgetRecordTagChoiceList(id) {
  return axios.get(API_WIDGETRECORD_TAGCHOICE_LIST + id);
}

//TagChoice Add
export const API_WIDGETRECORD_TAGCHOICE_ADD = "/Entity/TagChoice/WidgetRecord/Add";
export function apiWidgetRecordTagChoiceAdd() {
  return axios.get(API_WIDGETRECORD_TAGS_ADD);
}

//TagChoice Get
export const API_WIDGETRECORD_TAGCHOICE_GET = "/Entity/TagChoice/WidgetRecord/Get?id=";
export function apiEntityTagsetWidgetrecordGet(id) {
  return axios.get(API_WIDGETRECORD_TAGCHOICE_GET + id);
}

//TagChoice Update
export const API_WIDGETRECORD_TAGCHOICE_UPDATE = "/Entity/TagChoice/WidgetRecord/Update/";
export function apiEntityTagsetWidgetrecordUpdate(id, value) {
  return axios.patch(API_WIDGETRECORD_TAGCHOICE_UPDATE + id, value);
}
//TagChoice Delete
export const API_ENTITY_TAGCHOICE_DELETE = "/Entity/TagChoice/WidgetRecord/Remove";
export function apiEntityTagChoiceRemove() {
  return axios.delete(API_ENTITY_TAGCHOICE_DELETE);
}

//TagChoice/Tag/Add
export const API_ENTITY_TAGCHOICE_TAG_ADD = "Entity/TagChoice/Tag/Add";
export function apiEntityTagChoiceTagAdd() {
  return axios.get(API_ENTITY_TAGCHOICE_TAG_ADD);
}
//Entity Types
export const API_ENTITY_ENTITY_LIST = "Entity/EntityTypes";

export function apiEntityEntityList() {
  return axios.get(API_ENTITY_ENTITY_LIST);
}

//Entity/WidgetTypes

export const API_ENTITY_WIDGET_LIST = "Entity/WidgetTypes";

export function apiEntityWidgetTypesList(id) {
  return axios.get(API_ENTITY_WIDGET_LIST + "?entity_type=" + id);
}

//Entity/WidgetTypes

export const API_ENTITY_TAGSET_WIDGET_GET = "/Entity/Tagset/WidgetRecord/Get";

export function apiEntityWidgetTagSetGet(id) {
  return axios.get(API_ENTITY_TAGSET_WIDGET_GET + "?id=" + id);
}

//ConfirmationStatus
export const API_CONFIRMATION_STATUS = "/Entity/ConfirmationStatus";

export function apiConfirmationStatus() {
  return axios.get(API_CONFIRMATION_STATUS);
}




// node setting api start 

export const ORG_TREE_NODE_ADDRESS_CREATE = "/Org/Tree/NodeAddress/Create/"
export function postOrgTreeNodeAddressCreate(id, data) {
  return axios.post(ORG_TREE_NODE_ADDRESS_CREATE + id, data);
}

export const ORG_TREE_NODE_ADDRESS_UPDATE = "/Org/Tree/NodeAddress/Update/"

export function patchOrgTreeNodeAddressUpdate(id, data) {
  return axios.patch(ORG_TREE_NODE_ADDRESS_UPDATE + id, data);
}



export const ORG_TREE_NODE_ADDRESS_GET = "/Org/Tree/NodeAddress/Get/"

export function getOrgTreeNodeaddressGet(id) {
  return axios.get(ORG_TREE_NODE_ADDRESS_GET + id);
}

// node setting api end 


//Honours
export const API_LIST_HONOURS = "/Entity/Education/ListHonours";
export function apiListHonours() {
  return axios.get(API_LIST_HONOURS);
}

//Country,state API with search
export const API_SEARCH_PLACE_LIST = "/Org/PlacesList?";
export function apiPlaceSearch(type, value) {
  return axios.get(API_SEARCH_PLACE_LIST + "type=" + type + "&value=" + value);
}

// Donation Dasboard
export const API_ENTITY_DONATION_STATISTICSOVERVIEW = "/Entity/Donation/StatisticsOverview";
export function apiEntityDonationStatisticOverview() {
  return axios.get(API_ENTITY_DONATION_STATISTICSOVERVIEW + "?service_instance=" + org_instance);
}

export const API_ENTITY_DONATION_STATISTIC = "/Entity/Donation/Statistics";
// export function apiEntityDonationStatistic() {
//   return axios.get(API_ENTITY_DONATION_STATISTIC + "?service_instance=" + org_instance);
// }


//List DataTypes
export const API_ENTITY_LIST_DATATYPES = "/Entity/ListDatatypes";
export function apiEntityListDataTypes() {
  return axios.get(API_ENTITY_LIST_DATATYPES);
}




// faq apis 

export const ENTITY_EVENT_QANDA_RECORD_ADD = "/Entity/Event/QandA/Record/Add"

export function postEntityQandARecordAdd(data) {
  return axios.post(ENTITY_EVENT_QANDA_RECORD_ADD, data);
}

export const ENTITY_EVENT_QANDA_RECORD_UPDATE = "/Entity/Event/QandA/Record/Update/"

export function patchEntityQandARecordUpdate(id, data) {
  return axios.patch(ENTITY_EVENT_QANDA_RECORD_UPDATE + id, data);
}

export const ENTITY_EVENT_QUANDA_RECORD_LIST = "/Entity/Event/QandA/Record/List"

export function getEntityQandARecordList(id) {
  return axios.get(ENTITY_EVENT_QUANDA_RECORD_LIST + "?widget=" + id);
}

export const ENTITY_EVENT_QANDA_RECORD_REMOVE = "/Entity/Event/QandA/Record/Remove/"

export function deleteEntityEventQandRecordRemove(id) {
  return axios.delete(ENTITY_EVENT_QANDA_RECORD_REMOVE + id);
}

export const ORG_CONTACTS_CONTACT_TYPES_LIST = "/Org/Contacts/ContactTypes/List/"

export function getOrgContactsContactTypesList() {
  return axios.get(ORG_CONTACTS_CONTACT_TYPES_LIST)
}

export const ENTITY_SCHOLARSHIP_PLAN_LIST_PULIC = "Entity/Scholarship/Plan/PublicList"

export const ENTITY_EVENT_QANDA_RECORD_ADD_MEDIA = "/Entity/Event/QandA/Record/AddMedia/"
export function patchEntityQandaRecordAddMedia(id, data) {
  return axios.patch(ENTITY_EVENT_QANDA_RECORD_ADD_MEDIA + id, data);
}
//Blood group list
export const API_LIST_BLOOD_GROUPS = "/Entity/BloodGroup/Types";
export function apiListBloodGroups() {
  return axios.get(API_LIST_BLOOD_GROUPS);
}

//Entity Tagset Add
export const API_ENTITY_TAGSET_ADD = "Entity/Tagset/Add"
export function ApiEntityTagsetAdd(data) {
  return axios.post(API_ENTITY_TAGSET_ADD + data);
}

//Entity Tagset Delete
export const API_ENTITY_TAGSET_REMOVE = "Entity/Tagset/Remove";
export function apiEntityTagSetRemove() {
  return axios.delete(API_ENTITY_TAGSET_REMOVE);
}

//Entity tagset get
export const API_ENTITY_TAGSET_GET = "Entity/Tagset/Get?id="
export function apiEntityTagsetGet(id) {
  return axios.get(API_ENTITY_TAGSET_GET + id);
}

//Entity tagset Update
export const API_ENTITY_TAGSET_UPDATE = "Entity/Tagset/Update/"
export function apiEntityTagsetUpdate(id, data) {
  return axios.patch(API_ENTITY_TAGSET_UPDATE + id, data);
}

//Entity Tagvalue Remove
export const API_ENTITY_TAGVALUE_REMOVE = "Entity/TagValues/WidgetRecord/Remove/";
export function apiEntityTagValueRemove(id) {
  return axios.delete(API_ENTITY_TAGVALUE_REMOVE + id + "/");
}

////UnlockUser////
export const API_UNLOCK_USER = "Org/Auth/UnLock/User"
export function apiUnlock(data) {
  return axios.patch(API_UNLOCK_USER, data);
}

//Report CK editior Image add API
export const API_REPORT_CKEDITOR_ADD_IMAGE = "Entity/AddAttachment/"
export function apiReportCkEditiorImageAdd(data) {
  return axios.post(API_REPORT_CKEDITOR_ADD_IMAGE, data);
}

export const ORG_SUBSCRIBEEMAIL_LIST = "Org/SubscribeEmail/List/"


export const WALLET_VARIANTS_LIST = "Wallet/Variants/List"
export function getWalletVariantList() {
  return axios.get(WALLET_VARIANTS_LIST);
}
export const WALLET_VARIANTS_GET = "Wallet/Variants/Get/"
export function getWalletVariantGet(id) {
  return axios.get(WALLET_VARIANTS_GET + id);
}
export const WALLET_VARIANTS_ADD = "Wallet/Variants/Add"
export function postWalletVariantAdd(data) {
  return axios.post(WALLET_VARIANTS_ADD, data);
}

export const WALLET_VARIANTS_UPDATE = "Wallet/Variants/Update/"
export function patchWalletVariantUpdate(id, data) {
  return axios.patch(WALLET_VARIANTS_UPDATE + id, data);
}

export const WALLET_VARIANTS_REMOVE = "Wallet/Variants/Remove"
export function deleteWalletVariantList(id) {
  return axios.delete(WALLET_VARIANTS_REMOVE + "/" + id);
}


export const ENTITY_WALLETS_TYPES = "Entity/Wallets/Types"
export function getEntityWalletsTypes() {
  return axios.get(ENTITY_WALLETS_TYPES)
}

// wallet Api
export const ENTITY_WALLET_LIST = "Entity/Wallets/List"
export function getEntityWalletList(id) {
  return axios.get(ENTITY_WALLET_LIST + "?service_instance=" + id);
}
export const ENTITY_WALLET_GET = "Entity/Wallets/Get/"
export function getEntityWalletGet(id) {
  return axios.get(ENTITY_WALLET_GET + id);
}
export const ENTITY_WALLET_ADD = "Entity/Wallets/Add"
export function postEntityWalletAdd(data) {
  return axios.post(ENTITY_WALLET_ADD, data);
}

export const ENTITY_WALLET_UPDATE = "Entity/Wallets/Update/"
export function patchEntityWalletUpdate(id, data) {
  return axios.patch(ENTITY_WALLET_UPDATE + id, data);
}

export const ENTITY_WALLET_REMOVE = "Entity/Wallets/Remove"
export function deleteEntityWalletList(id) {
  return axios.delete(ENTITY_WALLET_REMOVE + "/" + id);
}


export const ORG_POLICY_ROLE_POLICY_UPDATE = "Org/Policy/RolePolicy/Update/"
export function patchOrgPolicyRolePolicyUpdate(id, data) {
  return axios.patch(ORG_POLICY_ROLE_POLICY_UPDATE + id + "/", data);
}


export const ORG_POLICY_ROLE_POLICY_GET = "Org/Policy/RolePolicy/Get/"

export function getOrgPolicyRolePolicyGet(id) {
  return axios.get(ORG_POLICY_ROLE_POLICY_GET + id + "/")
}

export const ORG_POLICY_ROLE_POLICY_LIST = "Org/Policy/RolePolicy/List/"
export function getOrgPolicyRolePolicyList(id) {
  return axios.get(ORG_POLICY_ROLE_POLICY_LIST + "?role_id=" + id)
}


export const ORG_LIST_PLAT_FORM = "Org/ListPlatform"
export function getOrgListPlatForm() {
  return axios.get(ORG_LIST_PLAT_FORM)
}




// header change APi List // header change APi List // header change APi List // header change APi List 


export const instance = axios;

// add new user
export function postUser(values) {
  return axios.create(defaultOptions).post(ORG_USER_PRIVATE_SIGNUP, values);
}

// delete user
export function deleteUser(id) {
  return axios.create(defaultOptions).delete(ORG_USER_REMOVE + id + "/");
}

export function deleteRole(id) {
  return axios.delete(ORG_ROLES_REMOVE + id + "/");
}

export function deleteRoleService(id) {
  return axios.delete(ORG_ROLES_SERVICE_REMOVE + id + "/");
}

export function getRoleGroupList() {
  return axios.create(defaultOptions).get(ORG_ROLES_GROUP_LIST);
}

export function getRole(id) {
  return axios.create(defaultOptions).get(ORG_ROLES_GETROLEDETAILS + id + "/");
}

export function updateRole(data, id) {
  return axios.create(defaultOptions).patch(ORG_ROLES_UPDATE + id + "/", data);
}

export function createRole(data, id) {
  return axios.create(defaultOptions).post(ORG_ROLES_CREATE, data);
}

// Get User List
export function getUserLists() {
  return axios.create(defaultOptions).get(
    ORG_USER_USER_DETAIL_LIST + "?service_instance_id=" + org_instance
  );
}

// Get Role List
export function getRoleLists() {
  return axios.create(defaultOptions).get(
    ORG_ROLES_LIST + "?service_instance_id=" + org_instance
  );
}

// Add Post Api
export const ORG_POST_ADD = "/Org/Posts/Add/";
export function createPost(data) {
  return axios.create(defaultOptions).post(ORG_POST_ADD, data);
}


// Post GetApi
export const ORG_POST_GET = "/Org/Posts/Get/";
export function getPost(id) {
  return axios.create(defaultOptions).get(ORG_POST_GET + id + "/");
}

// Post List GetApi
export function getPostList() {
  return axios.create(defaultOptions).get(ORG_POST_LIST + "?org_instance_id=" + service_instanceID);
}

// Post UpdateApi
export const ORG_POST_UPDATE = "/Org/Posts/Update/";
export function updatePost(data, id) {
  return axios.create(defaultOptions).patch(ORG_POST_UPDATE + id + "/", data);
}

// Role DeleteApi
export const ORG_POSTS_REMOVE = "/Org/Posts/Remove/";
export function deletePost(id) {
  return axios.create(defaultOptions).delete(ORG_POSTS_REMOVE + id + "/");
}

// Role Service List Get
export const ORG_ROLES_SERVICE_LIST = "/Org/Roles/Service/List/";

export function getRoleServiceList(id, options) {

  return axios.create(options).get(
    ORG_ROLES_SERVICE_LIST + "?service_instance_id=" + id
  );
}

// Assign Role Add Api
export const ORG_ROLES_SERVICE_INSTANCE_ADD =
  "/Org/Roles/Service/Instance/Add/";
export function createAssignRole(data) {
  return axios.create(defaultOptions).post(
    ORG_ROLES_SERVICE_INSTANCE_ADD,
    data
  );
}

// Assign Role Add Api
export const ORG_ROLES_SERVICE_INSTANCE_GET =
  "/Org/Roles/Service/Instance/Get/";
export function getServiceInstanceRole(id) {
  return axios.create(defaultOptions).get(
    ORG_ROLES_SERVICE_INSTANCE_GET + id + "/"
  );
}


// app setting  
export const ORG_APP_MANAGER_LIST = "Org/AppManager/List/?service_instance="

export function getOrgAppManagerList(id) {
  return axios.get(
    ORG_APP_MANAGER_LIST + id
  );
}

export const ORG_APP_MANAGER_GET = "Org/AppManager/get/"
export function getOrgAppManagerGet(id) {
  return axios.get(
    ORG_APP_MANAGER_GET + id
  );
}

export const ORG_APP_MANAGER_ADD = "Org/AppManager/Add/"

export function postOrgAppManagerAdd(data) {
  return axios.post(
    ORG_APP_MANAGER_ADD,
    data
  );
}

export const ORG_APP_MANAGER_UPDATE = "/Org/AppManager/Update/"

export function patchOrgAppManagerUpdate(id, data) {
  return axios.patch(
    ORG_APP_MANAGER_UPDATE + id,
    data
  );
}

export const ORG_APP_MANAGER_REMOVE = "Org/AppManager/Remove/"

export function deleteOrgAppManagerRemove(id) {
  return axios.delete(
    ORG_APP_MANAGER_REMOVE + id
  );
}

// app verion  

export const ORG_APP_VERSION_LIST = "/Org/AppVersion/List/"

export function geOrgAppVersionList() {
  return axios.get(
    ORG_APP_VERSION_LIST
  );
}

export const ORG_APP_VERSION_GET = "/Org/AppVersion/Get/"

export function geOrgAppVersionGet(id) {
  return axios.get(
    ORG_APP_VERSION_GET + id
  );
}

export const ORG_APP_VERSION_ADD = "Org/AppVersion/Add/"
export function postAppVersionAdd(data) {
  return axios.post(
    ORG_APP_VERSION_ADD, data
  );
}
export const ORG_APP_VERSION_UPDATE = "Org/AppVersion/Update/"
export function patchAppVersionUpdate(id, data) {
  return axios.patch(
    ORG_APP_VERSION_UPDATE + id, data
  );
}
export const ORG_APP_VERSION_REMOVE = "Org/AppVersion/Remove/"
export function deleteAppVersionRemove(id) {
  return axios.delete(
    ORG_APP_VERSION_REMOVE + id
  );
}
// Person Address add api
export const API_PERSON_ADDRESS_ADD =
  "Entity/Person/AddressDetails/Add";
export function apiPersonAddress(data) {
  return axios.post(API_PERSON_ADDRESS_ADD, data);
}
// Person Address Update api
export const API_PERSON_ADDRESS_UPDATE =
  "Entity/Person/AddressDetails/Update";
export function apiPersonAddressUpdate(data) {
  return axios.patch(API_PERSON_ADDRESS_UPDATE, data);
}
// Person Address List api
export const API_PERSON_ADDRESS_LIST =
  "Entity/Person/AddressDetails/List?user=";
export function apiPersonAddressList(id) {
  return axios.get(
    API_PERSON_ADDRESS_LIST + id
  );
}

// donation setting Apis EntityDonationSettings/Add

export const ENTITY_DONATION_SETTING_LIST = "Entity/DonationSettings/List/?service_instance="

export function getEntityDonationSettingsList(id) {
  return axios.get(
    ENTITY_DONATION_SETTING_LIST + id
  );
}

export const ENTITY_DONATION_SETTING_GET = "Entity/DonationSettings/Get/"
export function getEntityDonationSettingsGet(id) {
  return axios.get(
    ENTITY_DONATION_SETTING_GET + id
  );
}

export const ENTITY_DONATION_SETTING_ADD = "Entity/DonationSettings/Add"

export function postEntityDonationSettingsAdd(data) {
  return axios.post(
    ENTITY_DONATION_SETTING_ADD,
    data
  );
}

export const ENTITY_DONATION_SETTING_UPDATE = "/Entity/DonationSettings/Update/"

export function patchEntityDonationSettingsUpdate(id, data) {
  return axios.patch(
    ENTITY_DONATION_SETTING_UPDATE + id,
    data
  );
}

export const ENTITY_DONATION_SETTING_REMOVE = "Entity/DonationSettings/Remove/"

export function deleteEntityDonationSettingsRemove(id) {
  return axios.delete(
    ENTITY_DONATION_SETTING_REMOVE + id
  );
}


// Get 
export const ORG_TREE_NODE_CONFIG_GET = "/Org/Tree/NodeConfig/Get/";

export function getOrgTreeNodeConfigGet(id) {
  return axios.get(ORG_TREE_NODE_CONFIG_GET + id + "/");
}

export const ORG_CONTACTS_SOCIAL_MEDIA_LIST = "/Org/Contacts/SocialMedia/List/"

export function getOrgContactsSocialMediaList() {
  return axios.get(ORG_CONTACTS_SOCIAL_MEDIA_LIST);
}

export const ORG_TREE_NODE_SOCIL_MEDIA_ADD = "/Org/Tree/Node/SocialMedia/Add"

export function postOrgTreeNodeSocilMediaAdd(data) {
  return axios.post(ORG_TREE_NODE_SOCIL_MEDIA_ADD, data);
}


export const ORG_TREE_NODE_SETTING_UPDATE = "/Org/Tree/NodeSetting/Update"


export function patchOrgTreeNodeSettingUpdate(data) {
  return axios.patch(ORG_TREE_NODE_SETTING_UPDATE, data);
}


export const ORG_TREE_NODE_SETTING_ADD = "/Org/Tree/NodeSetting/Add";
export function postOrgTreeNodeSettingAdd(data) {
  return axios.post(ORG_TREE_NODE_SETTING_ADD, data);
}
export const ORG_TREE_NODE_SETTING_LIST = "/Org/Tree/NodeSetting/List"

export function getOrgTreeNodeSettingList() {
  return axios.get(ORG_TREE_NODE_SETTING_LIST);
}

export const ORG_TREE_NODE_SETTING_SOCIAL_MEDIA_CONTANCT_LIST = "/Org/Tree/NodeSetting/SocialMediaContact/List";

export function getOrgTreeNodeSettingSocialMediaList(id) {
  return axios.get(ORG_TREE_NODE_SETTING_SOCIAL_MEDIA_CONTANCT_LIST + "?node_id=" + id);
}
// /Entity/Event/Contribution/Record/List 


// Entity/Widget/Contribution 

export const ENTITY_EVENT_CONTRIBUTION_RECORD_LIST = "Entity/Event/Contribution/Record/List"

export function getEntityEventContributionRecordList(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_LIST + "?event_entity=" + id
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_GET = "Entity/Event/Contribution/Record/Get/"
export function getEntityEventContributionRecordGet(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_GET + id
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_ADD = "Entity/Event/Contribution/Record/Add"

export function postEntityEventContributionRecordAdd(data) {
  return axios.post(
    ENTITY_EVENT_CONTRIBUTION_RECORD_ADD,
    data
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_UPDATE = "/Entity/Event/Contribution/Record/Update/"

export function patchEntityEventContributionRecordUpdate(id, data) {
  return axios.patch(
    ENTITY_EVENT_CONTRIBUTION_RECORD_UPDATE + id,
    data
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_REMOVE = "Entity/Event/Contribution/Record/Remove/"

export function deleteEntityEventContributionRecordRemove(id) {
  return axios.delete(
    ENTITY_EVENT_CONTRIBUTION_RECORD_REMOVE + id
  );
}


export const ENTITY_EVENT_CONTRIBUTION_RECORD_LIST_WALLETS = "Entity/Event/Contribution/Record/ListWallets"
export function getEntitEventContributionRecordListWallets(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_LIST_WALLETS + "?event_entity=" + id
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_DOWNLOAD_RECEIPT = "Entity/Event/Contribution/Record/DownloadReceipt/"
export function getEntityEventContributionRecordDownloadReceipt(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_DOWNLOAD_RECEIPT + id,
    { responseType: 'blob' }
  );
}

export const ENTITY_EVENT_CONTRIBUTION_RECORD_SEND_EMAIL = "Entity/Event/Contribution/Record/SendEmail/"
export function getEntityEventContributionRecordSendEmail(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_SEND_EMAIL + id
  );
}



export const ENTITY_EVENT_CONTRIBUTION_RECORD_GET_BY_RECEIPT = "Entity/Event/Contribution/Record/GetByReceipt/"
export function getEntityEventContributionRecordGetByReceipt(id) {
  return axios.get(
    ENTITY_EVENT_CONTRIBUTION_RECORD_GET_BY_RECEIPT + id
  );
}

// /Entity/Event/Expense/Record/UpdateUnPaid/{id}
export const ENTITY_EVENT_CONTRIBUTION_RECORD_UPDATEUNPAID = "/Entity/Event/Contribution/Record/UpdateUnPaid/"

export function patchEntityEventContributionRecordUpdateUnpaid(id, data) {
  return axios.patch(
    ENTITY_EVENT_CONTRIBUTION_RECORD_UPDATEUNPAID + id,
    data
  );
}

// Entity/Event/Contribution/Record/UploadReceipts
export const ENTITY_EVENT_CONTRIBUTION_RECORD_UPLOAD_RECEIPTS = "Entity/Event/Contribution/Record/UploadReceipts/"
export function patchEntityEventContributionRecordUploadReceipts(data) {
  return axios.patch(
    ENTITY_EVENT_CONTRIBUTION_RECORD_UPLOAD_RECEIPTS, data
  );
}



// Entity/Widget/Donation  /Entity/Widget/Donation/Record/Add

export const ENTITY_WIDGET_DONATION_RECORD_LIST = "Entity/Widget/Donation/Record/List"

export function getEntityWidgetDonationRecordList(id) {
  return axios.get(
    ENTITY_WIDGET_DONATION_RECORD_LIST + "?widget=" + id
  );
}

export const ENTITY_WIDGET_DONATION_RECORD_GET = "Entity/Widget/Donation/Record/Get/"
export function getEntityWidgetDonationRecordGet(id) {
  return axios.get(
    ENTITY_WIDGET_DONATION_RECORD_GET + id
  );
}

export const ENTITY_WIDGET_DONATION_RECORD_ADD = "Entity/Widget/Donation/Record/Add"

export function postEntityWidgetDonationRecordAdd(data) {
  return axios.post(
    ENTITY_WIDGET_DONATION_RECORD_ADD,
    data
  );
}

export const ENTITY_WIDGET_DONATION_RECORD_UPDATE = "/Entity/Widget/Donation/Record/Update/"

export function patchEntityWidgetDonationRecordUpdate(id, data) {
  return axios.patch(
    ENTITY_WIDGET_DONATION_RECORD_UPDATE + id,
    data
  );
}

export const ENTITY_WIDGET_DONATION_RECORD_REMOVE = "Entity/Widget/Donation/Record/Remove/"

export function deleteEntityWidgetDonationRecordRemove(id) {
  return axios.delete(
    ENTITY_WIDGET_DONATION_RECORD_REMOVE + id
  );
}


export const ENTITY_DONATION_STOP_CONDITION_TYPES = "Entity/Donation/StopConditionTypes"
export function getEntityDonationStopConditionTypes() {
  return axios.get(
    ENTITY_DONATION_STOP_CONDITION_TYPES
  );
}
// Widget/Participant/Add
export const API_EVENT_PARTICIPANT_ADD =
  "/Entity/Widget/Participant/Add";
export function apiParticipantAdd(data) {
  return axios.post(API_EVENT_PARTICIPANT_ADD, data);
}

// Widget/Participant/List
export const API_EVENT_PARTICIPANT_LIST =
  "Entity/Widget/Participant/List";
export function apiParticipantList() {
  return axios.get(API_EVENT_PARTICIPANT_LIST);
}

// Widget/Participant/List
export const API_EVENT_PARTICIPANT_GET =
  "Entity/Widget/Participant/Get/";
export function apiParticipantGet(id) {
  return axios.get(API_EVENT_PARTICIPANT_GET + id);
}

//participant delete
export const API_EVENT_PARTICIPANT_REMOVE =
  "Entity/Widget/Participant/Remove/";
export function apiParticipantRemove(id) {
  return axios.delete(API_EVENT_PARTICIPANT_REMOVE + id);
}

//Participant Update
export const API_EVENT_PARTICIPANT_UPDATE =
  "Entity/Widget/Participant/Update/";
export function apiParticipantUpdate(id, data) {
  return axios.patch(
    API_EVENT_PARTICIPANT_UPDATE + id, data
  );
}

//Role Service Add
export const API_ROLE_SERVICE_ADD =
"Org/Roles/Service/Add/"
export function apiRoleServiceAdd(data) {
  return axios.post(API_ROLE_SERVICE_ADD, data);
}

//Role Service Get
export const API_ROLE_SERVICE_GET =
  "Org/Roles/Service/Get/";
export function apiRoleServiceGet(id) {
  return axios.get(API_ROLE_SERVICE_GET + id + "/");
}

// currency list Api 

export const CURRENCY_LIST = "currency/";
export function getCurrencyList() {
  return axios.get(CURRENCY_LIST);
}
export const GET_COUNTRY_LIST = "country/"


//Active User API
export const API_PERSON_LOGINSTATISTICS =
  "Entity/Person/LoginStatistics/Get";
export function apiPersonLoginStatistics(id) {
  return axios.get(API_PERSON_LOGINSTATISTICS + "?service_instance=" + id);
}


// Expense Api /Entity/Event/Expense/Record/Add

export const ENTITY_EVENT_EXPENSE_RECORD_LIST = "Entity/Event/Expense/Record/List"

export function getEntityEventExpenseRecordList(id) {
  return axios.get(
    ENTITY_EVENT_EXPENSE_RECORD_LIST + "?event_entity=" + id
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_GET = "Entity/Event/Expense/Record/Get/"
export function getEntityEventExpenseRecordGet(id) {
  return axios.get(
    ENTITY_EVENT_EXPENSE_RECORD_GET + id
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_ADD = "Entity/Event/Expense/Record/Add"

export function postEntityEventExpenseRecordAdd(data) {
  return axios.post(
    ENTITY_EVENT_EXPENSE_RECORD_ADD,
    data
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_UPDATE = "/Entity/Event/Expense/Record/Update/"

export function patchEntityEventExpenseRecordUpdate(id, data) {
  return axios.patch(
    ENTITY_EVENT_EXPENSE_RECORD_UPDATE + id,
    data
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_REMOVE = "Entity/Event/Expense/Record/Remove/"

export function deleteEntityEventExpenseRecordRemove(id) {
  return axios.delete(
    ENTITY_EVENT_EXPENSE_RECORD_REMOVE + id
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_DOWNLOAD_RECEIPT = "Entity/Event/Expense/Record/DownloadReceipt/"
export function getEntityEventExpenseRecordDownloadReceipt(id) {
  return axios.get(
    ENTITY_EVENT_EXPENSE_RECORD_DOWNLOAD_RECEIPT + id,
    { responseType: 'blob' }
  );
}

export const ENTITY_EVENT_EXPENSE_RECORD_SEND_EMAIL = "Entity/Event/Expense/Record/SendEmail/"
export function getEntityEventExpenseRecordSendEmail(id) {
  return axios.get(
    ENTITY_EVENT_EXPENSE_RECORD_SEND_EMAIL + id
  );
}




export const ENTITY_EVENT_EXPENSE_RECORD_ADD_DOCUMENT = "Entity/Event/Expense/Record/AddDocument/"
export function patchEventExpenseRecordAddDocument(id, data) {
  return axios.patch(
    ENTITY_EVENT_EXPENSE_RECORD_ADD_DOCUMENT + id + "/",
    data
  );
}
// /Entity/Event/Expense/Record/UpdateUnPaid/{id}
export const ENTITY_EVENT_EXPENSE_RECORD_UPDATEUNPAID = "Entity/Event/Expense/Record/UpdateUnPaid/"

export function patchEntityEventExpenseRecordUpdateUnpaid(id, data) {
  return axios.patch(
    ENTITY_EVENT_EXPENSE_RECORD_UPDATEUNPAID + id
  );
}



///-------Category Widget API Start------------////
export const ENTITY_CATEGORY_WIDGETS_ADD = "Entity/EntityCategoryWidgets/Add"
export function postEntitycategoryWidgetAdd(data) {
  return axios.post(
    ENTITY_CATEGORY_WIDGETS_ADD, data)
}

export const ENTITY_CATEGORY_WIDGETS_LIST = "Entity/EntityCategoryWidgets/List"
export function EntitycategoryWidgetList() {
  return axios.get(
    ENTITY_CATEGORY_WIDGETS_LIST
  );
}

export const ENTITY_CATEGORY_WIDGETS_GET = "Entity/EntityCategoryWidgets/Get/"
export function EntitycategoryWidgetGet(id) {
  return axios.get(ENTITY_CATEGORY_WIDGETS_GET + id + "/");
}

export const ENTITY_CATEGORY_WIDGETS_REMOVE = "Entity/EntityCategoryWidgets/Remove/"
export function EntitycategoryWidgetRemove(id) {
  return axios.delete(ENTITY_CATEGORY_WIDGETS_REMOVE + id + "/");
}
///-------Category Widget API End------------////


// Subscriptions API_________start______________Subscriptions API

// List
export const ENTITY_SUBCRIPTIONS_LIST = "Entity/Subscriptions/List"
export function getEntitySubcriptionsList(id) {
  return axios.get(ENTITY_SUBCRIPTIONS_LIST + "?event_entity=" + id);
}
// Get
export const ENTITY_SUBCRIPTIONS_GET = "Entity/Subscriptions/Get/"
export function getEntitySubcriptionsGet(id) {
  return axios.get(ENTITY_SUBCRIPTIONS_GET + id);
}
// ADD
export const ENTITY_SUBCRIPTIONS_ADD = "Entity/Subscriptions/Add"
export function postEntitySubcriptionsAdd(data) {
  return axios.post(ENTITY_SUBCRIPTIONS_ADD, data);
}
// Update
export const ENTITY_SUBCRIPTIONS_UPDATE = "Entity/Subscriptions/Update/"
export function patchEntitySubcriptionsUpdate(id, data) {
  return axios.patch(ENTITY_SUBCRIPTIONS_UPDATE + id, data);
}
// Delete
export const ENTITY_SUBCRIPTIONS_REMOVE = "Entity/Subscriptions/Remove"
export function deleteEntitySubcriptionsList(id) {
  return axios.delete(ENTITY_SUBCRIPTIONS_REMOVE + "/" + id);
}



// Subscriptions Options API_________start______________Subscriptions Options API

// List
export const ENTITY_SUBCRIPTIONS_OPTIONS_LIST = "Entity/SubscriptionOptions/List"
export function getEntitySubcriptionsOptionsList(id) {
  return axios.get(ENTITY_SUBCRIPTIONS_OPTIONS_LIST + "?event_entity=" + id);
}
// Get
export const ENTITY_SUBCRIPTIONS_OPTIONS_GET = "Entity/SubscriptionOptions/Get"
export function getEntitySubcriptionsOptionsGet(id) {
  return axios.get(ENTITY_SUBCRIPTIONS_OPTIONS_GET + "/" + id);
}
// ADD
export const ENTITY_SUBCRIPTIONS_OPTIONS_ADD = "Entity/SubscriptionOptions/Add"
export function postEntitySubcriptionsOptionsAdd(data) {
  return axios.post(ENTITY_SUBCRIPTIONS_OPTIONS_ADD, data);
}
// Update
export const ENTITY_SUBCRIPTIONS_OPTIONS_UPDATE = "Entity/SubscriptionOptions/Update"
export function patchEntitySubcriptionsOptionsUpdate(data) {
  return axios.patch(ENTITY_SUBCRIPTIONS_OPTIONS_UPDATE, data);
}
// Delete
export const ENTITY_SUBCRIPTIONS_OPTIONS_REMOVE = "Entity/SubscriptionOptions/Remove"
export function deleteEntitySubcriptionsOptionsList(id) {
  return axios.delete(ENTITY_SUBCRIPTIONS_OPTIONS_REMOVE + "/" + id);
}


// Day list

export const ENTITY_LIST_DAYS = "Entity/ListDays"
export function getEntityListDaysList() {
  return axios.get(ENTITY_LIST_DAYS);
}
// Mounth list

export const ENTITY_LIST_MONTH = "Entity/ListMonth"
export function getEntityListMonthList() {
  return axios.get(ENTITY_LIST_MONTH);
}


export const ENTITY_LIST_OCCURRANCE_PERIOD_LIST = "Entity/ListOccurrancePeriod"
export function getEntityListOccurrancePeriodList() {
  return axios.get(ENTITY_LIST_OCCURRANCE_PERIOD_LIST);
}

// level varient Api

export const ORG_TREE_LEVEL_VARIANT_LIST = "Org/Tree/LevelVariant/List";
export function getOrgTreeLevelVarientList(id) {
  return axios.get(ORG_TREE_LEVEL_VARIANT_LIST + "?node_id=" + id);
}

// ENTITY node Add
export const ENTITY_NODE_ADD = "Entity/Node/Add";
export function postEntityNodeAdd(data) {
  return axios.post(ENTITY_NODE_ADD, data);
}
export const ORG_TREE_NODE_PATCH = "Org/Tree/Node/Update/";
export function patchOrgTreeNodePatch(id, data) {
  return axios.patch(ORG_TREE_NODE_PATCH + id, data);
}
// node remove Api 
export const ORG_TREE_NODE_REMOVE = "Org/Tree/Node/Remove/"
export function deleteOrgTreeNodeRemove(id) {
  return axios.delete(ORG_TREE_NODE_REMOVE + id);
}


// node list
export const ORG_TREE_NODE_LIST = "Org/Tree/Node/List";
export function getOrgTreeNodeList() {
  return axios.get(ORG_TREE_NODE_LIST);
}


// Entity service instance
export const ENTITY_SERVICE_INSTANCE_ADD = "Entity/ServiceInstance/Add";
export function postEntityServiceInstanceAdd(data) {
  return axios.post(ENTITY_SERVICE_INSTANCE_ADD, data);
}

export const ORG_POSTS_USER_POST_LIST = "Org/Posts/UserPostList/";
export function getOrgPostsUserPostList(id) {
  return axios.get(ORG_POSTS_USER_POST_LIST + "?user_id=" + id + "&org_instance_id=" + service_instanceID);
}




export const ORG_TREE_LIST_LEVEL_VARIANT_UNIQUE_TYPES = "Org/Tree/ListLevelVariantUniqueTypes";
export function getOrgTreeListVarientUniqueTypes() {
  return axios.get(ORG_TREE_LIST_LEVEL_VARIANT_UNIQUE_TYPES);
}



export const ORG_SERVICE_SERVICE_INSTANCES_LIST_WITH_NODE = "Org/Services/ServiceInstances/ListWithNode/";
export function getOrgServiceInstancesListWithNode() {
  return axios.get(ORG_SERVICE_SERVICE_INSTANCES_LIST_WITH_NODE);
}


export const ORG_SERVICE_VARIANT_LIST = "Org/ServiceVariant/List";
export function getOrgServiceVariantList() {
  return axios.get(ORG_SERVICE_VARIANT_LIST);
}



// Membership  Membership API

// List
export const ENTITY_MEMEBERSHIP_PLAN_LIST = "Entity/Membership/Plan/List"
export function getEntityMemberrshipPlanList(id) {
  return axios.get(ENTITY_MEMEBERSHIP_PLAN_LIST + "?event_entity=" + id);
}
// Get
export const ENTITY_MEMEBERSHIP_PLAN_GET = "Entity/Membership/Plan/Get/"
export function getEntityMemberrshipPlanGet(id) {
  return axios.get(ENTITY_MEMEBERSHIP_PLAN_GET + id);
}
// ADD
export const ENTITY_MEMEBERSHIP_PLAN_ADD = "Entity/Membership/Plan/Add"
export function postEntityMemberrshipPlanAdd(data) {
  return axios.post(ENTITY_MEMEBERSHIP_PLAN_ADD, data);
}
// Update
export const ENTITY_MEMEBERSHIP_PLAN_UPDATE = "Entity/Membership/Plan/Update"
export function patchEntityMemberrshipPlanUpdate(data) {
  return axios.patch(ENTITY_MEMEBERSHIP_PLAN_UPDATE, data);
}
// Delete
export const ENTITY_MEMEBERSHIP_PLAN_REMOVE = "Entity/Membership/Plan/Remove"
export function deleteEntityMemberrshipPlanRemove(id) {
  return axios.delete(ENTITY_MEMEBERSHIP_PLAN_REMOVE + "/" + id);
}
export const ENTITY_EVENT_CONTRIBUTION_PAYMENT_LINK_CREATE = "Entity/Event/Contribution/PaymentLink/"

export function getEntityEventContributionPaymentLinkAdd(id) {
  return axios.patch(
    ENTITY_EVENT_CONTRIBUTION_PAYMENT_LINK_CREATE + id
  );
}

//ID card delete API connect

export const ID_CARD_DOWNLOAD_GET_API = "Entity/Subscriptions/ID/Get/"
export function getIdCardDownloadApi(id) {
  return axios.get(ID_CARD_DOWNLOAD_GET_API + id, { responseType: 'blob' });
}

// //------------UI Common Template Api Start-----------//

// //======Section API Call============//
// //section List
// export const API_SECTIONLIST = "Entity/Widget/List"
// export function sectionsListApiCall(id) {
//   return axios.get(API_SECTIONLIST + "/" + id);
// }

// //section Get
// export const API_SECTION_GET = "Entity/Widget/Get/"
// export function sectionsAddGetApiCall(id) {
//   return axios.get(API_SECTION_GET + id);
// }

// //section Add
// export const API_SECTIONADD = "Entity/SubSections/Create"
// export function sectionsAddApiCall(data) {
//   return axios.post(API_SECTIONADD, data);
// }

// //section update
// export const API_SECTION_UPDATE = "Entity/Widget/Update/"
// export function sectionsUpdateApiCall(data, id) {
//   return axios.patch(API_SECTION_UPDATE + id, data);
// }

// //section Delete
// export const API_SECTION_REMOVE = "Entity/Widget/Remove/"
// export function sectionDeleteApiCall(id) {
//   return axios.delete(API_SECTION_REMOVE + id);
// }


// //======SubSection API Call============//

// //SubSection Delete
// export const API_SUB_SECTION_REMOVE = "Entity/SubSections/Remove"
// export function subSectionDeleteApiCall(id) {
//   return axios.delete(API_SUB_SECTION_REMOVE + "/" + id);
// }

// //SubSection List
// export const API_SUB_SECTION_LIST = "/Entity/SubSections/List/"
// export function subSectionsListApiCall(id) {
//   return axios.get(API_SUB_SECTION_LIST + id);
// }



// //SubSection Add
// export const API_SUB_SECTION_ADD = "Entity/SubSections/Add"
// export function subSectionsAddApiCall(data) {
//   return axios.post(API_SUB_SECTION_ADD, data);
// }


// //SubSection AddImage API

// export const API_SUB_SECTION_IMAGE_ADD = "Entity/SubSections/AddImage/"
// export function subSectionsImageAddApiCall(id, data) {
//   return axios.post(API_SUB_SECTION_IMAGE_ADD + id, data);
// }


// //section Get
// export const API_SUB_SECTION_GET = "Entity/SubSections/Get/"
// export function subSectionsAddGetApiCall(id) {
//   return axios.get(API_SUB_SECTION_GET + id);
// }

// //subsection update API
// export const API_SUB_SECTION_UPDATE = "Entity/SubSections/Update/"
// export function subSectionsUpdateApiCall(data, id) {
//   return axios.patch(API_SUB_SECTION_UPDATE + id, data);
// }
// //------------UI Common Template Api Start-----------//
