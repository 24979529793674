import { refreshAccessToken } from "./authCrud";
import { language } from "../../helpers/Utils"
import { useEffect } from 'react';
import Axios from "axios";
import { HTTP_CODE } from "../ApiConst";
import { toast } from "react-toastify";
import ShowError from "../../common/errorHandler/ShowError"

export default function setupAxios(
  axios,
  // store,
  base_url,
  setError,
  firm,
  token,
  ref_token,
  post_id,
  session_id,
  config,
  auth
) {
  const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/"

  function refreshAccessToken(data) {
    return Axios.post(ORG_AUTH_REFRESH, data);
  }
  axios.defaults.baseURL = base_url;

  // const language = localStorage.getItem("language");

  const refToken = ref_token;
  const postId = post_id;
  const sessionId = session_id;
  var refreshToken = localStorage.getItem("ref_token");

  axios.interceptors.request.use(
    async (config) => {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        config.headers = {
          Authorization: "Basic " + localStorage.getItem("x-token"),
          "accept-language": language(),
        };
      } else {
        config.headers = {
          Authorization: "Basic " + localStorage.getItem("x-token"),
          "X-Authorization": `Bearer ${authToken}`,
          "post-id": localStorage.getItem("postId"),
          "session-id": localStorage.getItem("session_id"),
          "service-instance-id": localStorage.getItem("service_instance_id"),
          region: "uk",
          organization: "eln",
          "Accept-Language": language()
        };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }

  );
  
  axios.interceptors.response.use(response => response, (error) => {
    
    ShowError(error, setError)

    const statusCode = error.response ? error.response.status : null;
    statusResponse(statusCode);
    return Promise.reject(error);
  });

  const statusResponse = (code) => {
    if (code === 401) {
      if (refreshToken !== null) {
        Axios.post(ORG_AUTH_REFRESH, { refresh_token: refreshToken })
          .then((resp) => {
            if (resp.status == HTTP_CODE.OK) {
            
              localStorage.setItem("token", resp?.data?.access_token);
            }
            else {
              localStorage.clear();
              // window.location.href = "/";
              
            }
          })
          .catch((e) => {
            toast.error("Session Time Out", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
            });
            localStorage.clear();
            window.location.href = "/";
            // showDeleteError();
            setLoading(false);

          })
      }
    }
  };
  
}
