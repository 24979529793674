import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
// import { connect } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
// import * as auth from "../_redux/authRedux";
import { requestPassword,verifyForgetPasswordEmail } from "../Api/authCrud";
// import ReactCodeInput from "react-verification-code-input";
import ReactCodeInput from "../pages/verification_code_input";

import { ErrorMessage, SuccessMessage } from "../../../components/CustomAlert";
// import { FormattedMessage, injectIntl } from "react-intl";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { resendLinks } from "../Api/authCrud";

const initialValues = {
  email: "",
};

function EmailVerify(props) {
  const { onEmailVerifySuccess, onEmailVerifyFailed, intl, org_instance_id } = props;
  const history = useNavigate();
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("demo@gmail.com");

  const location = useLocation();

  useEffect(() => {
    let id = queryString.parse(location.search);
    setEmail(id.email);
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      requestPassword(values.email)
        .then((resp) => {
          setIsRequested(true);
          onEmailVerifySuccess(resp.data);
        })
        .catch((e) => {
          disableLoading();
          setIsRequested(false);
          setSubmitting(false);
          ErrorMessage({ message: "Invalid Verification Code!" });
          onEmailVerifyFailed(e);
        });
    },
  });

  let onOnclickHandler = () => {
    verifyForgetPasswordEmail (email,OTP)
      .then((resp) => {
        history({
          pathname: "/auth" + resp.data.data.context,
          // search: "?code=" + OTP,
        });
      })
      .catch((error) => {
        history("/verification-link-expired");
      });
  };

  let resendOTP = () => {
    // resendLinks(email)
    Axios.post("/forgot-password", { email: email, org_instance_id: org_instance_id }).then(
      (resp) => {
        SuccessMessage({ message: "Resend Code Successfully Sent" });
      }
    );
  };

  const [OTP, setOTP] = useState("");

  return (
    <>
      {isRequested && (
        // <Redirect to="/auth" />
        <Routes>
          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      )}

      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Verification</h3>
            <img
              src={
                "https://user-images.githubusercontent.com/4661784/56352614-4631a680-61d8-11e9-880d-86ecb053413d.png"
              }
              style={{ width: "150px" }}
              alt="Logo"
            />

            <div className="text-muted fw-bold mt-5">
              Please enter the verification code we send to your email address
              <span className="fw-bold ml-2" id="kt_login_signup">
                {email}
              </span>
            </div>
          </div>

          <div
            className="form-group fv-plugins-icon-container pl-8"
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* <ReactCodeInput value={OTP} type="text" onChange={setOTP} /> */}
            <ReactCodeInput sx={{width:"300px"}} type='number' fields={4} value={OTP} onChange={setOTP} />

          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <span
              className="text-dark-50 text-hover-primary my-3 mr-2 ml-15"
              id="kt_login_forgot"
              onClick={resendOTP}
            >
              {/* <FormattedMessage id="AUTH.GENERAL.VERIFI_CODE" /> */}
            </span>
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4"
              onClick={onOnclickHandler}
            >
              Submit
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default EmailVerify;
