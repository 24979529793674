import * as Actions from './UserRoleAction';
// import { lightTheme } from '../themes/theme';


const initialState = {
  userRole: {}
};

export const UserRoleReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case Actions.APPLY_USER_ROLE:
      return Object.assign({}, { userRole: action.payload } );
    default:
      return state;
  }
};