import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import black from "../../assets/Images/blank.png";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { useParams } from "react-router-dom";
import axios from "axios";

export function SignupVerificationLinkSent(props) {
  const [email, setEmail] = useState("demo@gmail.com");
  const [logoName, setLogoName] = useState(null);
  const [appName,setAppName] = useState(null)
  const { intl, org_instance_id } = props;
  const toAbsoluteUrl = process.env.REACT_APP_API_URL
  const location = useLocation();
  

  useEffect(() => {
    const search = location.search;
  const name = new URLSearchParams(search).get('email');
    setEmail(name)
  }, []);
  
  
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phn_nbr, setphn_nbr] = useState("");
  const history = useNavigate();

 
  function resendLink() {
    axios
      .post("/resend-verification-email-signup", { email: email, org_instance_id: org_instance_id })

      .then((resp) => {
        if (resp.status === 200) {
          toast.success(resp?.data?.massage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          showUserDeleteError(resp?.data?.massage);
        }
      });
  }

  const showUserDeleteError = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };



  const [OTP, setOTP] = useState("");

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Login*/}
          <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={
              {
                // backgroundImage: `url(${toAbsoluteUrl("/media/bg/progress-hd.png")})`,
              }
            }
          >
            <div className="d-flex flex-column flex-column-fluid text-center p-10 justify-content-center">
              <div style={{ background: "white" }}>
                <div className="pt-lg-10 mr-t-15">
                  {/* <img
                    alt="Logo"
                    className="h-50px mb-10"
                    src={logoName == "" ? "" : logoName }
                  /> */}
                </div>
                <div className="pt-lg-10 mr-b-20">
                  <img
                    src={
                      "https://images-na.ssl-images-amazon.com/images/I/51Dop0XlFmL.png"
                    }
                    style={{ width: "150px" }}
                    alt="Logo"
                    className="mb-10"
                  />
                  <h1
                    className=" text-dark mb-7"
                    style={{ fontSize: "30px", fontWeight: 600 }}
                  >
                    Verify Your Email
                  </h1>

                  <div
                    className="text-gray-400 mb-10 text-muted fw-bold"
                    style={{ fontSize: "20px" }}
                  >
                    We have sent a link to registered Email ID
                    <Link to=""> {email}</Link> <br></br>
                    Please follow the link to verify your email.
                  </div>

                  {/* {webConfigData.config.features.signup.emailVerification.mandatory == true && */}
                  <div className="text-center mb-10">
                    <Link to="/auth/login">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-diff-clr fw-bold px-9 py-4 my-3`}
                      >
                        Skip for now
                      </button>
                    </Link>
                  </div>
                  {/* } */}

                  {/* {webConfigData.config.features.signup.emailVerification.type === 1 && */}
                  <div className="font-size-h6">
                    <span className="fw-bold text-gray-700">
                      Did’t receive an email?
                    </span>
                    <Link to="" className="font-size-h4" onClick={resendLink}>
                      {" "}
                      Resend
                    </Link>
                  </div>
                  {/* } */}
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
