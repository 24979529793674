import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
// import { connect } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import * as Yup from "yup";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
import { resetPassword, ORG_AUTH_RESET_PASSWORD } from "../Api/authCrud";
import { useNavigate } from "react-router-dom";
// import { Login } from './Login'
// import { useSelector } from "react-redux";
import axios from "axios"
// import Popper from "@mui/material/Popper";
// import Typography from "@mui/material/Typography";
// import Fade from "@mui/material/Fade";
// import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { HTTP_CODE } from "../pages/Const";
// import useClasses from "../../../helpers/useClasses";
import { toast } from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';

const useStyles = (theme) => ({
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
});

const initialValues = {
  password: "",
  changepassword: "",
};

function ResetPassword(props) {
  const [isRequested, setIsRequested] = useState(false);
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const { onResetPasswordSuccess, onResetPasswordFailed, intl } = props;
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [token, setToken] = useState("");
  const location = useLocation();

  useEffect(() => {
    let id = queryString.parse(location.search);
    setToken(id.token);
  }, []);

  var passwordRegex = localStorage.getItem("regex");
  var passwordErrorMessage = localStorage.getItem("regex_mes");

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, passwordErrorMessage)
      // .max(50, "Maximum 50 symbols")
      .required(
        "Password is Required"
      ),
    changepassword: Yup.string()
      .required(
        "Confirm Password is Required"
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      axios.post(ORG_AUTH_RESET_PASSWORD,
        {
          new_password: formik.values.password,
          confirm_password: formik.values.changepassword,
          token: token
        })
        .then((resp) => {
          setLoading(false)
          history(resp.data.data.context);
          // disableLoading();
          if (resp.status === 200) {

          } else {
          }
          onResetPasswordSuccess(resp.data);
        })
        .catch((e) => {
          setLoading(false)
          toast.error(e?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          history(resp.response.data.data.context);
          // disableLoading();
          setStatus(
            // intl.formatMessage({ id: "AUTH.VALIDATION.TOKEN_EXPIRED" })
          );
          // history(resp.data.data.context);
          // history("/auth/forgot-password");
          onResetPasswordFailed(e);
        });
    },
  });

  return (
    <>

      <div className="login-form login-signin" >
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password</h3>
          <div className="text-muted fw-bold">
            Enter your email to reset your password
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text fw-bold">{formik.status}</div>
            </div>
          )}

          {/* begin: Password */}
          <div
            className="form-group fv-plugins-icon-container"
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handleClick("top-end")}
            onBlur={handleClick("top-end")}
          >
            <input
              placeholder="New Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              // onChange={e => setPassword(e.target.value)}
              {...formik.getFieldProps("password")}
            />

            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block"><small className="text-danger">{formik.errors.password}</small></div>
              </div>
            ) : null}


          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Confirm Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "changepassword"
              )}`}
              name="changepassword"
              {...formik.getFieldProps("changepassword")}
            // onChange={e => setPasswordAgain(e.target.value)}
            />

            {formik.touched.changepassword && formik.errors.changepassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <small className="text-danger"> {formik.errors.changepassword} </small>
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            {/* <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4"
              // disabled={formik.isSubmitting}
              onClick={formik.onSubmit}
            >
              Submit
              {loading && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </button> */}
            <LoadingButton
              style={{ padding: "12px" }}
              id="kt_login_signin_submit"
              type="submit"
              loading={loading}
              onClick={formik.onSubmit}
              loadingPosition="center"
              loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
              className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button`}
            >
              <span>{t(`Submit`)}</span>
            </LoadingButton>

            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary fw-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
      {/* )} */}
    </>
  );
}

export default ResetPassword;
