import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import "datatables.net-responsive";
import "./index.css";
import { legacy_createStore as createStore, combineReducers } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";
import { themeReducer as theme } from "./redux/ThemeReducer";
import { UserRoleReducer as userRole } from "./redux/UserRoleReducer";
import { Suspense } from "react";
import { useNavigate } from "react-router";

import { Navigate, Routes, Route } from "react-router-dom";
import ProfileList from "./User/ProfileList";
import { UserCards } from "./User/UserCards";
import UserForm from "./User/UserForm";
import "./AuthRoutePage/pcstyle.css";
import {
  VerificationLinkEmail,
  EmailVerificationSuccess,
  EmailVerificationExpired,
  EmailVerificationLoading,
  PhoneVerificationSuccess,
} from "./User/verification";
import { VerificationLinkPhone } from "./User/verification/VerificationLinkPhone";
import axios from "axios";
import * as _new from "./components/setupAxios";
// import store from "../Components/redux/store";
// import AdressEdit from "../User/ProfileInfo/AdressEdit";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./AuthRoutePage/pcstyle.css";
// import { Navigate, Routes, Route } from "react-router-dom";
import { AuthPage } from "./modules/Auth";
import { getServiceInstance } from "./modules/Auth/Api/authCrud";
import { gerServiceInstanceList, getAuthByToken } from "../src/components/ApiConst"
import { PUpdateSuccess } from "./modules/Auth/pages/FP_email_verification/PUpdateSuccess";
import { PUpdateFailed } from "./modules/Auth/pages/FP_email_verification/PUpdateFailed";
import { SignupVerificationLinkSent } from "./modules/Auth/pages/signup_email_verification/SignupVerificationLinkSent";
import { SignupVerificationCodeSent } from "./modules/Auth/pages/signup_email_verification/SignupVerificationCodeSent";
import { SignupVerificationSuccess } from "./modules/Auth/pages/signup_email_verification/SignupVerificationSuccess";
import { SignupVerificationExpired } from "./modules/Auth/pages/signup_email_verification/SignupVerificationExpired";
import { SignupVerificationLoading } from "./modules/Auth/pages/signup_email_verification/SignupVerificationLoading";
import FPVerificationCodeSent from "./modules/Auth/pages/FP_email_verification/FPVerificationCodeSent";
import { FPVerificationLinkSent } from "./modules/Auth/pages/FP_email_verification/FPVerificationLinkSent";
import { FPVerificationExpired } from "./modules/Auth/pages/FP_email_verification/FPVerificationExpired";
import { FPVerificationSuccess } from "./modules/Auth/pages/FP_email_verification/FPVerificationSuccess";
import FPVerificationotpSent from "./modules/Auth/pages/FP_email_verification/FPVerificationotpSent";
import ScholarshipDataTable from "./Datatable/ScholarshipDataTable";
import EducationDatatable from "./Datatable/EducationDatatable";
import { BrowserRouter } from "react-router-dom";
import AuthRoutePage from "./AuthRoutePage";
import LoadingSpinner from "./components/LoaderSmall";
import { applyUserRole } from "./redux/UserRoleAction"
import { WindowSharp } from "@mui/icons-material";
import { getOrgIdLocal } from "./helpers/Utils";
import Home from "./pages/home";
// import { ErrorContext } from "./App";

export const ErrorContext = createContext<any>([]);

// import AuthRoutePage from '@ellantec/pearlcore_auth_ui/dist/AuthRoutePage';

const App = React.lazy(() => import("./App"));

document.title = "ELLANTEC";

function Root() {

  const [loading, setLoading] = React.useState(true);
  const base_url = process.env.REACT_APP_API_URL;
  const ORGANIZATION_ID = getOrgIdLocal();

  const [error, setError] = useState<any>(["permission"])
  const [sidePanelPermission, setSidePanelPermission] = React.useState("");
  const x_token = process.env.REACT_APP_X_TOKEN;
  const [userRoleJson, setUserRoleJson] = React.useState();
  const [userRolePermission, setUserRolePermission] = React.useState("" as any);
  const rootReducer = combineReducers({ theme, userRole })

  const store = createStore(rootReducer);

  localStorage.setItem("x-token", x_token as any);

  useEffect(() => {
    setupFun();
    webconfig();
    getAuthByToken().then((elm: any) => {
      setUserRoleJson(elm)
      setUserRolePermission(elm)
      localStorage.setItem("user_id", elm?.data?.data?.person_entity?.user as any);
      // setUserRoleJson(elm.data.data.posts[0].roles_data.permissions);
    });


  }, []);

  function webconfig() {

    getServiceInstance(ORGANIZATION_ID)
      .then((res) => {
        var configData = res.data.data;        
        setLoading(false);
        localStorage.setItem("org_instance_id", ORGANIZATION_ID as any);
        localStorage.setItem("regex", res.data.data.regex as any);
        localStorage.setItem("regex_mes", res.data.data.regex_message as any);
        localStorage.setItem("event_entity", res?.data?.data?.org_entity_data?.event_entity as any);
        localStorage.setItem("country_id", res.data.data.country_id as any);
        localStorage.setItem("service_id",res.data.data.service_data.service_id);
        localStorage.setItem("persist:config",JSON.stringify(configData, null, 2));

      })

  }

  const setupFun = () => {


    _new.setupAxios(axios, base_url, setError);
  }

  const [isAuthorized, setIsAuthorized] = React.useState(
    "token" in localStorage &&
    "postId" in localStorage &&
    "session_id" in localStorage
  );

  // ===================UserRole Filter(Admin, student ect...)=======================//
  const current_Role = localStorage.getItem("role_filter_id");
  const filter = userRolePermission?.data?.data?.posts.filter((elm: any) => {
    return current_Role === elm?._id.toString()
  })
  useEffect(() => {
    if (filter !== undefined) {
      setSidePanelPermission(filter[0]?.roles_data?.permissions)
    }
  }, [filter !== undefined])
  // ===================UserRole Filter(Admin, student ect...)=======================//

  return (
    <>
      {/* <React.StrictMode> */}
      <Provider store={store}>

        <Suspense fallback={<div>Loading...</div>}>
          {!isAuthorized ? (
            <>
              <ToastContainer />
              <BrowserRouter>
                {ORGANIZATION_ID !== null ? (
                  <Routes>
                    <Route
                      path="/auth/*"
                      element={
                        <AuthPage
                          org_instance_id={ORGANIZATION_ID}
                          // loginResponce={loginResponce}
                          onLoginSuccess={function (response: any): void {
                            if (response.status === 202) {
                              // setIsAuthorized(true);
                              // let RollName = response.data.data.posts[0].roles_data.landing_page_data.page_name;
                              let categoryName = response.data.data.category_name;
                              let entityId = response.data.data.person_entity.user;

                              localStorage.setItem(
                                "User_name",
                                response.data.data.person_entity.personal_info.fname
                              );
                              localStorage.setItem(
                                "role_name",
                                response.data.data.posts[0].roles_data.roles_name
                              );
                              localStorage.setItem("role_filter_id", response?.data?.data?.posts[0]?._id);
                              gerServiceInstanceList().then((elm: any) => {
                                localStorage.setItem("service_instance_id", elm?.data?.results[0]?.instance_id);
                                ////======================********Old Code********=============== *////
                                // if (categoryName == "Admin" || "CorePerson") {
                                //   window.location.href = "/";
                                // } else {
                                //   window.location.href = "/ProfileAllList/profile-detail/" + entityId;
                                // }
                                ////======================********Old Code********=============== *////

                                if (sidePanelPermission !== 'null') {
                                  window.location.href = "/";
                                } else {
                                  window.location.href = "/ProfileAllList/profile-detail/" + entityId;
                                }


                              })
                              //   setIsAuthorized(true);
                              //   window.location.href = "/";
                            }
                          }}
                          onLoginFailed={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onSignupFailed={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onSignupSuccess={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onForgotPasswordSuccess={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onForgotPasswordFailed={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onResetPasswordSuccess={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onResetPasswordFailed={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onEmailVerifySuccess={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onEmailVerifyFailed={function (response: any): void {
                            throw new Error("Function not implemented.");
                          }}
                          onSignupMobileOtpSentSuccess={function (
                            response: any
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          onSignupMobileOtpSentFailed={function (
                            response: any
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          node_id={1}
                          base_url={base_url}
                        />
                      }
                    />

                    <Route
                      path="/password_reset_successfully"
                      element={<PUpdateSuccess />}
                    />
                    <Route
                      path="/verification-session-expired"
                      element={<PUpdateFailed />}
                    />

                    <Route
                      path="/forgot_password_verification_otp_sent_to_email"
                      element={
                        <FPVerificationCodeSent org_instance_id={ORGANIZATION_ID} />
                      }
                    />
                    <Route
                      path="/forgot_password_verification_otp_sent_to_phone"
                      element={
                        <FPVerificationotpSent org_instance_id={ORGANIZATION_ID} />
                      }
                    />

                    <Route
                      path="/forgot_password_verification_link_sent_to_email"
                      element={
                        <FPVerificationLinkSent org_instance_id={ORGANIZATION_ID} />
                      }
                    />
                    <Route
                      path="/forgot_password_email_verified_successfully"
                      element={
                        <FPVerificationSuccess org_instance_id={ORGANIZATION_ID} />
                      }
                    />
                    <Route
                      path="/forgot_password_email_verification_failed"
                      element={
                        <FPVerificationExpired org_instance_id={ORGANIZATION_ID} />
                      }
                    />
                    <Route
                      path="/password-updated-successfully"
                      element={<PUpdateSuccess org_instance_id={ORGANIZATION_ID} />}
                    />
                    <Route
                      path="/password-update-failed"
                      element={<PUpdateFailed />}
                    />

                    {/* ----------------------------------SIGNUP------------------------------------------------------------ */}
                    <Route
                      path="/signed_up_verification_link_sent_to_email"
                      element={
                        <SignupVerificationLinkSent
                          org_instance_id={ORGANIZATION_ID}
                        />
                      }
                    />
                    <Route
                      path="/signed_up_verification_otp_sent_to_email"
                      element={
                        <SignupVerificationCodeSent
                          org_instance_id={ORGANIZATION_ID}
                        />
                      }
                    />
                    <Route
                      path="/signed_up_email_verified_successfully"
                      element={
                        <SignupVerificationSuccess
                          org_instance_id={ORGANIZATION_ID}
                        />
                      }
                    />
                    <Route
                      path="/verification-link-expired"
                      element={
                        <SignupVerificationExpired
                          org_instance_id={ORGANIZATION_ID}
                        />
                      }
                    />

                    <Route
                      path="/verification-in-progress/:id"
                      element={
                        <SignupVerificationLoading
                          org_instance_id={ORGANIZATION_ID}
                        />
                      }
                    />
                    {/* ----------------------------------SIGNUP------------------------------------------------------------ */}

                    <Route path="*" element={<Navigate to="/auth/login" />} />
                    {/* <Route path="/logout" element={<Logout />} /> */}
                  </Routes>
                ) : (
                  <Home />
                )}

              </BrowserRouter>
            </>
          ) : (<>
            {ORGANIZATION_ID !== null ? (
              <>
                <ErrorContext.Provider value={[error, setError]}>
                  <App data={userRoleJson} />
                </ErrorContext.Provider>
              </>
            ) : (<Home />)}
          </>
          )}
        </Suspense>
      </Provider>
      {/* </React.StrictMode> */}

      {/* <ToastContainer /> */}
    </>
  );
}

export default Root;
