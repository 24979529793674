import React, { useState } from "react";
import { language } from "../../../helpers/Utils";

export default function SelectField({
  name,
  onChange,
  value,
  onBlur,
  list,
  label,
  isDisabled,
  noDefault,
  defaultValue,
  onValueChange
}) {
 
  const [data] = useState([
    {
      id: "msc",
      name: "language 1",
    },
    {
      id: "mac",
      name: "language 2",
    },
  ]);
  if (!list) {
    return (
      <select
        className={`pc-form-select pc-form-select-lg pc-form-select-solid ${!isDisabled ? "show-dropdown-icon" : " curser-disable"}`}
        name={name}
        value={value}
        onChange={onChange}
        onValueChange={onValueChange}
        onBlur={onBlur}
        disabled={isDisabled}
        defaultValue={defaultValue}
      >
        <option value=""> {language() == "en" ? "Select" : "தேர்ந்தெடு"} {label !== null ? label : name}</option>
        {data.map((item) => {
          return (
            <option value={item.id || item.value} key={item.id || item.value}>
              {item.name}
            </option>
          );
        })}
      </select>
    );
  } else {
    return (
      <select
        className={`pc-form-select pc-form-select-lg pc-form-select-solid ${!isDisabled ? "show-dropdown-icon " : "curser-disable"}`}
        name={name}
        value={value}
        onChange={onChange}
        onValueChange={onValueChange}
        onBlur={onBlur}
        disabled={isDisabled}
        defaultValue={defaultValue}
      >
        {noDefault !== true && (
          <option value="">{language() == "en" ? "Select" : "தேர்ந்தெடு"} </option>
        )}
        {list.length>0 && list.map((item) => {
          return (
            <option value={item.id || item.value} key={item.id || item.value}>
              {item.name || item.value || item.Document_name || item.type|| item.variant_name || item.id }
            </option>
          );
        })}
      </select>
    );
  }
}
