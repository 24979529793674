import React, { createContext } from 'react'
import { toast } from 'react-toastify';
import HelperErrorMassage from '../../components/Massage/HelperErrorMassage';
import RouterPages from '../../components/RouterPages';
import { HTTP_CODE } from '../../helpers/HttpCode';
import "../../../src/index.css"

export default function ShowError(resp: any, setError: any) {

    if (resp?.response?.status == HTTP_CODE.BADREQUEST ||
        resp?.response?.status == HTTP_CODE.CONFLICT) {
            
        var message = "Unknown error (UI)";
        if (resp?.response?.data?.message?.length > 0) {
          
            message = resp?.response?.data?.message
        }
        else if (resp?.response?.data?.error?.length > 0) {
          
            message = resp?.response?.data?.error
        }
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: true,
            // autoClose: 2000,
        })
    }
    else if (resp?.response?.status == HTTP_CODE.PERMISSION_DENIED) {
     
        setError(resp?.response?.data?.more_info?.policy_error)
    }

    else if (resp?.response?.status == HTTP_CODE.SERVER_ERROR) {

        toast.error(resp?.response?.statusText, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
        })
    }

    else if (resp?.response?.status == HTTP_CODE.OK) {
        toast.success(resp?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
        })
    }
    else if (resp?.response?.status == HTTP_CODE.CREATED) {

        toast.success(resp?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
        })
    }

    else {

        toast.error(resp?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: true,
            autoClose: 2000,
        })

        
    }


}

