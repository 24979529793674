export const color = {
  default_text_color : "#181C32",
  Primary_Color: "#2b95e8",
  DANGER_COLOR: "#D72A2A",
  Hr_Color: '#ccc',
  IN_ACTIVE: '#ccc',
  //--------SIDEBAR----------------------//
  Sidebar_Background: "#1e1e2d",
  Sidebar_Text: "#9899ac",
  Sidebar_Text_Highlighted: "#ffffff",
  Sidebar_Icon: "#9899ac",
  Sidebar_Icon_Highlighted: "#0095e8",
  Sidebar_Responsive_Arrow_Icon: "#0095e8",
  Sidebar_List_Row_Highlighted: "rgba(0, 0, 0, 1)",
  Sidebar_Link_Background_Active: "#000",
  Sidebar_Link_Color_Active: "#fff",

  //----------HEADER----------------------//
  Header_Background: "#fff",
  Header_Mail_Icon: "#0095e8",
  Header_Notification_Icon: "#0095e8",
  Header_Global_Icon: "#0095e8",
  Avatar_Letter_Color: "#2b95e8",

  //----------Dashboard----------------------//

  Orders_Card_Background: "linear-gradient(30deg,#00c6ff,#0072ff)",
  Cancelled_Card_Background: "linear-gradient(30deg,#ED213A,#d5847b)",
  Delivered_Card_Background: "linear-gradient(30deg,#11998e,#38ef7d)",
  Revenue_Card_Background: "linear-gradient(30deg,#7F00FF,#E100FF)",
  Student_card_Background: " linear-gradient(30deg,#090909,#acaeb1)",
  Scholarship_Card_Background: "linear-gradient(30deg,#389f94,#086841)",
  Card_Count_Color: "#000",

  Service_Card_Background: "#fff",
  service_card_text_color: "#181C32",

  //----------Datatable Icons----------------------//
  Datatable_Icon_Color: "#ffffff",
  Datatable_Info_Icon_bgColor: "#2b95e8",
  Datatable_Clock_Icon_bgColor: "#99C4C8",
  Datatable_Edit_Icon_bgColor: "#413F42",
  Datatable_Pencil_Icon_bgColor: "#FAA404",
  Datatable_Delete_Icon_bgColor: "#FF4949",
  Datatable_List_Icon_bgColor: "#205375",
  Datatable_List_Icon_Gray_bgColor: "#C8D6D6",
  Datatable_Cancel_Icon_bgColor: "#F31616",
  Datatable_Tick_Icon_bgColor: "#04FA31",
  Sidebar_Title: "#fff",
  Sidebar_Responsive_Menu_Icon: "#a5aaad",
  //----------HEADER----------------------//
  Headre_User_Img_bg: "#008000",

  //----------TABLE-------------------------//
  Table_Edit_Icon: "#0095e8",
  Table_Delete_Icon: "#0095e8",
  Table_Menu_Icon: "#0095e8",
  Table_Info_Icon: "#0095e8",
  Oreder_Table_Action_Icon: "#0095e8",
  Table_Row_Background: "#f9f9f9",
  // Table_Row_Highlighted : ""

  Suces_Button: "#0095e8",
  Danger_BUtton: "",
  Warnning: "",

  //------------LOGO-------------//
  Logo_Uplod_bg: "rgb(246, 247, 248)",
  Logo_border: "#808080",

  Close_Icon: "#cf7070",
  Loading_bg: "rgba(16, 16, 16, 0.356)",
  ProgressBar_bg: "rgba(16, 16, 16, 0.5)",
  ProgressBar_Background: "#0095e8",
  Error_Text: "#D72A2A",

  //----------User----------------------//
  User_Card_Background: "linear-gradient(30deg,#ffffff,#ffffff)",
  User_Cancelled_Card_Background: "linear-gradient(30deg,#ED213A,#d5847b)",
  // Delivered_Card_Background: "linear-gradient(30deg,#11998e,#38ef7d)",
  // Revenue_Card_Background: "linear-gradient(30deg,#7F00FF,#E100FF)",
  // Card_Count_Color: "#ffffff",

};

export const Datatable_Icons = {
  Info_Icon: `<i style="color:${color.Datatable_Info_Icon_bgColor};" class="fa fa-info datatable_action_icons"></i>`,
  Clock_Icon: `<i style="color:${color.Datatable_Clock_Icon_bgColor};" class="fa fa-clock-o datatable_action_icons"></i>`,
  Edit_Icon: `<i style="color:${color.Datatable_Edit_Icon_bgColor};" class="fa fa-edit datatable_action_icons"></i>`,
  Delete_Icon: `<i style="color:${color.Datatable_Delete_Icon_bgColor};" class="fa fa-trash datatable_action_icons"></i>`,
  List_Icon: `<i style="color:${color.Datatable_List_Icon_bgColor};" class="fa fa-bars datatable_action_icons"></i>`,
  GalleryIcon: `<i style="color:${color.Datatable_List_Icon_bgColor};" class="fa fa-picture-o datatable_action_icons"></i>`,
  TickIcon: `<i style="color:${color.Datatable_Tick_Icon_bgColor};cursor: not-allowed;" class="fa fa-check datatable_action_icons"></i>`,
  CancelIcon: `<i style="color:${color.Datatable_Cancel_Icon_bgColor};cursor: not-allowed;" class="fa fa-times datatable_action_icons"></i>`,
  Pencil_Icon_Color: `<i style="color:${color.Datatable_Pencil_Icon_bgColor};" class="fa fa-pencil datatable_action_icons"></i>`,
  List_Icon_Gray: `<i style="color:${color.Datatable_List_Icon_Gray_bgColor};cursor: not-allowed;" class="fa fa-bars datatable_action_icons"></i>`,
  Policy_Icon: `<i style="color:${color.Datatable_Edit_Icon_bgColor};" class="fa fa-shield datatable_action_icons"></i>`,


};
