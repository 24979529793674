import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// import { toAbsoluteUrl } from "@ellantec/pearlcore_config/dist/_pearl/_helpers";
// import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { resendLinks } from "../../Api/authCrud";
import { SuccessMessage } from "../../../../components/CustomAlert";
// import { useParams } from "react-router";

export function FPVerificationLinkSent(props) {
  const [email, setEmail] = useState("demo@gmail.com");
  // const webConfigData = useSelector((state) => state.webConfig);
  const location = useLocation();
  const { id } = useParams();
  const toAbsoluteUrl = "";

  useEffect(() => {
    setEmail(id);
  }, []);

  function resendLink() {
    resendLinks(email).then(() => {
      SuccessMessage({ message: "Resend Link has Successfully Sent" });
    });
  }
  var configData = localStorage.getItem("persist:config");
  var webConfig =JSON.parse(configData)
  return (
    <>
      <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Login*/}
          <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
              backgroundImage: `url(${
                "/media/bg/progress-hd.png"
              })`,
            }}
          >
            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center">
              <div className="pt-lg-10">
                <img
                  alt="Logo"
                  className="h-50px mb-10"
                  src={webConfig.logo}
                />
              </div>
              <div className="pt-lg-10">
                <img
                  src={
                    "https://images-na.ssl-images-amazon.com/images/I/51Dop0XlFmL.png"
                  }
                  style={{ width: "150px" }}
                  alt="Logo"
                  className="mb-10"
                />
                <h1
                  className=" text-dark mb-7"
                  style={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Verify Your Email
                </h1>

                <div
                  className="text-gray-400 mb-10 text-muted fw-bold"
                  style={{ fontSize: "20px" }}
                >
                  We have sent a link to registered Email ID
                  <span> {email}</span> <br></br>
                  Please follow the link to verify your email.
                </div>

                {/* {webConfigData.config.features.signup.emailVerification
                  .mandatory == true && ( */}
                  <div className="text-center mb-10">
                    <Link to="/auth/login">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-diff-clr fw-bold px-9 py-4 my-3`}
                      >
                        Skip for now
                      </button>
                    </Link>
                  </div>
                {/* )} */}

                <div className="font-size-h6">
                  <span className="fw-bold text-gray-700">
                    Did’t receive an email?
                  </span>
                  <span className="font-size-h4" onClick={resendLink}>
                    {" "}
                    Resend
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="d-flex flex-center flex-column-auto p-10">
					<div className="d-flex align-items-center fw-bold fs-6">
						<a href="https://keenthemes.com/faqs" className="text-muted text-hover-primary px-2">About</a>
						<a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
						<a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
					</div>
				</div> */}
          </div>
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
