import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
import { HTTP_CODE } from "../../pages/Const";
import {verificationInprogress} from "../../Api/authCrud"
export function SignupVerificationLoading(props) {
  // const webConfigData = useSelector((state) => state.webConfig);
  const history = useNavigate();
  const { id } = useParams();

  var configData = localStorage.getItem("persist:config");
  var webConfig =JSON.parse(configData)

  useEffect(() => {
    const code = id;
    verificationInprogress( code)
      .then((resp) => {
        if (resp.status === 202 ) {
          history({
            pathname: resp.data.data.context,
            search: "?code=" + code,
          });
          // window.location.reload();
        } else {
          history(resp.data.data.context);
        }
      })
      .catch(() => {
        history("/verification-link-expired");
        // window.location.reload();
      });
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root"  style={{height:"100vh"}}>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Login*/}
          <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
              backgroundImage: `url(${
                "/media/bg/progress-hd.png"
              })`,
            }}
          >
            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center">
              <div className="pt-lg-10">
                <img
                  alt="Logo"
                  className="h-50px mb-10"
                  src={webConfig.logo}
                />
              </div>
              {/* <div className="pt-lg-10 mr-b-20"></div> */}
              <div className="pt-lg-10">
                {/* <Spinner
                  animation="grow"
                  variant="primary"
                  style={{ width: "10rem", height: "10rem" }}
                /> */}
                <h1 className="mt-10">Your Email Verification Loading...</h1>
              </div>
            </div>
          </div>
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
